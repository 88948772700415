// LOADER

.dwa-loader
  max-width: none!important

  .mat-dialog-container
    padding: 0
    box-shadow: none


  &-backdrop
    background-color: rgba($color-white, 80%)

  &-component
    @extend .dwa-flex-center

    height: 100%


  &-title
    font-size: 20px

  &-text
    font-size: 20px
    margin-left: 40px

    &-number
      font-weight: bold
