// SLIDETHUMBS

.dwa-slidethumbs
  @extend .dwa-card-style

  width: 100%
  margin: auto
  font-size: 0.85rem
  overflow: hidden
  box-sizing: border-box


  &-header
    @extend .dwa-card-style-header


  &-title
    @extend .dwa-card-style-title

    &-link
      @extend .dwa-hover-opacity

      color: $color-gray-2


  &-pagination
    display: flex

    &-button
      @extend .dwa-flex-center

      display: block
      width: $slidethumbs-pagination-button
      height: $slidethumbs-pagination-button
      color: $color-black
      background-color: $color-gray-5
      border-radius: $border-radius
      transition: opacity $layout-speed $layout-easing

      &:hover
        opacity: 0.8

      &:disabled
        opacity: 0.5
        pointer-events: none
        background-color: $color-gray-5
        color: $color-black


      &.prev
        margin-right: 4px
        transform: rotateZ(180deg)


    &-icon
      &-arrow
        display: block
        width: 100%
        height: 54%


  &-list
    display: flex
    padding: 15px 0 30px
    position: relative

    &-wrapper
      +hidden-scroll(x)
      padding-left: $slidethumbs-padding-left
      padding-right: $slidethumbs-padding-left - $slidethumbs-space-between


  &-item
    $width: calc(20% - #{$slidethumbs-space-between})
    flex: 0 0 $width
    max-width: $width
    padding-right: $slidethumbs-space-between

    +query-before(phablet, true)
      $width: calc(50% - #{$slidethumbs-space-between})
      flex: 0 0 $width
      max-width: $width

    &.one-third
      $width: calc(33.3333% - #{$slidethumbs-space-between})
      flex: 0 0 $width
      max-width: $width

      +query-before(phablet, true)
        $width: calc(100% - #{$slidethumbs-space-between})
        flex: 0 0 $width
        max-width: $width

    &:last-child
      padding-right: 35px
