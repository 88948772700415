// GENERAL
// Global

html
  overflow-y: auto
  overflow-x: hidden
  touch-action: manipulation

  &.cdk-global-scrollblock
    overflow-y: hidden


// Animations

@keyframes fadeOut
  from
    opacity: 1

  to
    opacity: 0

@keyframes fadeIn
  from
    opacity: 0

  to
    opacity: 1


// Classes

.dwa-flex-column
  display: flex
  flex-direction: column

.dwa-flex-center
  display: flex
  align-items: center
  justify-content: center


.dwa-absolute-full
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%


.dwa-hover
  &-opacity
    transition: opacity 0.2s

    &:hover
      opacity: 0.7


  &-opacity-inverse
    opacity: 0.5
    transition: opacity 0.2s

    &:hover
      opacity: 1!important


  &-color
    &-green
      transition: color 0.2s

      &:hover
        color: $color-green-1!important


    &-black
      transition: color 0.2s

      &:hover
        color: $color-black!important


    &-white
      transition: color 0.2s

      &:hover
        color: $color-white!important


.dwa-show-active
  animation: fadeOut 0.4s linear 0s forwards

  .active &
    animation: fadeIn 0.4s linear 0s forwards

.input-public 
    .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background
      background-color: $color-green-1

.mr-10
  margin-right: 10px

.flex-4
  flex: 4

.flex-1
  flex: 1

.full-width
  width: 100%

.mr-10
  margin-right: 10px