// FORMS
// Field

.dwa-field
  position: relative
  display: block
  min-width: 200px
  margin-bottom: $form-input-margin


  &-label
    position: absolute
    top: 11px
    left: 10px
    color: transparent
    opacity: 0.5

    .dwa-icon-font,
    .dwa-icon-brand
      color: $color-white
      background-color: $color-green-2
      font-size: 12px
      font-weight: 900
      padding: 8px
      border-radius: 50%


  &-input
    display: block
    box-sizing: border-box
    width: 100%
    color: $color-gray-2
    background-color: $color-white
    border-radius: $form-input-radius
    border: none
    font-size: $form-input-size
    line-height: $form-input-size + 2px
    padding-top: $form-input-padding
    padding-right: $form-input-padding
    padding-bottom: $form-input-padding
    padding-left: $form-input-padding + 30px
    box-shadow: inset 0 1px 1px rgba($color-black, 0.2)

    &.dropdown
      text-indent: $form-input-padding + 30px
      height: ($form-input-padding * 2) + ($form-input-size + 2px)

    &.textarea
      height: 100px

    &:focus
      outline: none
      background-color: $color-gray-1

    &:placeholder
      color: rgba($color-gray-2, 0.5)


// Button

.dwa-button
  display: block
  box-sizing: border-box
  box-shadow: 0 2px 1px rgba($color-black, 0.2)
  width: 100%
  color: $color-white
  background-color: $color-green-1
  border-radius: $form-input-radius
  border: none
  font-size: $form-input-size
  line-height: $form-input-size + 2px
  padding: $form-input-padding
  text-transform: uppercase
  cursor: pointer
  position: relative
  overflow: hidden

  &:disabled
    opacity: 0.5
    pointer-events: none

  &:before
    position: relative
    z-index: 1

  &:after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
    background-color: rgba($color-black, 0.1)
    transition: opacity ease-in-out 0.15s

  &:hover,
  &:focus
    outline: none

    &:after
      opacity: 1


  &.inline-button
    display: inline-block
    width: auto
    padding: $form-input-padding ($form-input-padding / 2)

  &.inner-shadow
    box-shadow: inset 0 2px 1px rgba($color-black, 0.2)


  &-text
    font-family: $font-main
    margin-left: 10px
    font-weight: normal


// Link

.dwa-link
  color: rgba($color-gray-2, 0.6)
  display: inline-block
  text-decoration: underline
  font-size: $form-input-size
  line-height: $form-input-size + 2px
  cursor: pointer

  &:hover,
  &:focus
    outline: none
    color: $color-gray-2
