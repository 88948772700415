// SLIDETHUMBS
// Vars

$slide-maxheight: 400px
$slide-pagination-bottom: 20px
$slide-pagination-border: 3px
$slide-pagination-button: 17px
$slide-pagination-button-space: 5px
$slide-content-padding-top: 68px
$slide-content-padding-left: 50px


//

.dwa-slide
  position: relative
  display: flex
  align-items: flex-end
  justify-content: center
  padding-bottom: $slide-pagination-bottom
  width: 100%
  height: 100vh
  max-height: $slide-maxheight
  margin: auto
  overflow: hidden
  border-radius: $border-radius

  &-pagination
    position: relative
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    padding: 0 $slide-content-padding-left
    z-index: 2
    &-button
      flex: 0 0 $slide-pagination-button
      height: $slide-pagination-button
      margin: $slide-pagination-button-space $slide-pagination-button-space
      border: $slide-pagination-border solid $color-white
      border-radius: 100%
      transition: transform $layout-speed $layout-easing

      &::before
        display: block
        content: ''
        width: 100%
        height: 100%
        background: $color-white
        border-radius: 100%
        transform: scale(0)
        transition: transform $layout-speed $layout-easing

      &:hover
        transform: scale(0.95)

      &.active
        cursor: default
        transform: scale(1)
        &::before
          transform: scale(1)


  &-list
    @extend .dwa-absolute-full

    z-index: 1


  &-item
    @extend .dwa-absolute-full

    z-index: 1

    &.active
      z-index: 2


    &-link
      @extend .dwa-hover-color-green

      position: relative
      display: flex
      align-items: flex-end
      height: 100%
      padding: $slide-content-padding-top $slide-content-padding-left
      color: $color-white
      box-sizing: border-box


    &-background
      @extend .dwa-absolute-full
      @extend .dwa-show-active

      background-position: 50%
      background-size: cover
      z-index: 1


    &-content
      @extend .dwa-show-active

      position: relative
      width: 50%
      opacity: 0
      z-index: 2

      @media (max-width: 768px)
        width: 100%


    &-title
      @extend .dwa-title-big

      padding-right: 20%

      @media (max-width: 768px)
        padding: 0


    &-subtitle
      @extend .dwa-subtitle
