//TYPOGRAPHY

html
  font-size: $font-size

body
  font-family: $font-main
  margin: 0
  color: $color-gray-2
  font-size: 1rem
  line-height: 1.5
  -webkit-font-smoothing: antialiased

strong
  font-weight: $font-bold


// Text

.dwa-text-big
  font-size: 1.15rem
  line-height: 1.6

.dwa-text
  font-size: 1rem
  line-height: 1.6

.dwa-text-small
  font-size: 0.9rem
  line-height: 1.6

.dwa-text-tiny
  font-size: 0.8rem
  line-height: 1.6


// Title

.dwa-title-big
  font-size: 2.7rem
  line-height: 1.3
  font-weight: $font-regular

.dwa-title
  font-size: 2rem
  line-height: 1.3
  font-weight: $font-regular

.dwa-title-small
  font-size: 1.65rem
  line-height: 1.3
  font-weight: $font-regular

.dwa-subtitle
  font-size: 1.45rem
  line-height: 1.3
  font-weight: $font-regular


// Utils

.truncate-text
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

//

.dwa-icon-font
  +icon-type(false)

.dwa-icon-brand
  +icon-type(true)
