// MESSAGES
// Vars

$help-search-padding: 25px 50px 50px
$help-search-title-margin-bottom: 20px
$help-search-field-padding: 0 25px
$help-search-icon: 27px
$help-search-input-height: 70px
$help-search-input-padding: 0 30px
$help-content-margin-top: 34px
$help-faqs-margin-right: 55px
$help-faqs-faq-posts-margin-bottom: 55px


$faq-posts-title-padding: 10px 28px
$faq-posts-title-margin-bottom: 28px
$faq-posts-item-link-padding: 14px 5px
$faq-posts-item-icon-size: 14px
$faq-posts-item-icon-margin: 3px 14px 0 0
$help-sidebar-title-margin-bottom: 38px
$help-sidebar-title-padding: 7px 0 11px
$help-sidebar-item-link-padding: 0 0 20px
$help-sidebar-item-icon-size: 14px
$help-sidebar-item-icon-margin: 3px 10px 0 0

//

.dwa-help
  &-search
    padding: $help-search-padding
    background-color: $color-green-1
    border-radius: $border-radius


    &-title
      @extend .dwa-title-big

      margin-bottom: $help-search-title-margin-bottom
      color: $color-white
      font-weight: $font-light
      text-align: center


    &-field
      display: flex
      height: $help-search-input-height
      padding: $help-search-field-padding
      background-color: $color-white
      border-radius: $border-radius


    &-icon
      flex: 0 0 $help-search-icon
      height: 100%
      color: $color-gray-6


    &-input
      @extend .dwa-text

      flex: 1 1 100%
      padding: $help-search-input-padding
      height: 100%


  &-content
    display: flex
    margin-top: $help-content-margin-top


  &-faqs
    flex: 1 1 60%
    margin-right: $help-faqs-margin-right

    .dwa-faq-posts
      margin-bottom: $help-faqs-faq-posts-margin-bottom


  &-sidebar
    flex: 1 1 40%


    &-title
      @extend .dwa-title-small

      margin-bottom: $help-sidebar-title-margin-bottom
      padding: $help-sidebar-title-padding
      color: $color-gray-4
      font-weight: $font-light
      text-transform: uppercase
      border-bottom: 1px solid $color-green-1


    &-list
      @extend .dwa-flex-column


    &-item
      &-link
        @extend .dwa-hover-color-green

        display: flex
        padding: $help-sidebar-item-link-padding
        color: $color-gray-4


      &-icon
        flex: 0 0 $help-sidebar-item-icon-size
        height: $help-sidebar-item-icon-size
        margin: $help-sidebar-item-icon-margin


      &-text
        @extend .dwa-text-big




.dwa-faq-posts
  &-title
    @extend .dwa-title-small

    padding: $faq-posts-title-padding
    margin-bottom: $faq-posts-title-margin-bottom
    color: $color-white
    background-color: $color-green-1
    border-radius: $border-radius


  &-list
    @extend .dwa-flex-column


  &-item
    border-bottom: 1px solid $color-border-topnav

    &-link
      @extend .dwa-hover-color-green

      display: flex
      padding: $faq-posts-item-link-padding
      color: $color-gray-4


    &-icon
      flex: 0 0 $faq-posts-item-icon-size
      height: $faq-posts-item-icon-size
      margin: $faq-posts-item-icon-margin


    &-text
      @extend .dwa-text-big
