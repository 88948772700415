.dwa-publication-dialog
  &-wrapper
    margin-top: $topnav-height

    .mat-dialog-container
      background-color: transparent
      overflow: hidden
      border-radius: 0
      padding: 0


  &-fullscreen
    transition: none
    height: 100vh!important
    width: 100vw!important

    .mat-dialog-container
      background-color: transparent
      overflow: hidden
      border-radius: 0
      padding: 0


  &-backdrop
    background-color: rgba($color-black, 0.8)


  &-content
    width: 100%
    height: 100%
    text-align: left
    background-color: $color-gray-2

    &.hide-controls
      position: absolute
      top: 2px
      left: 2px
      width: calc(100% - 4px)
      height: calc(100% - 4px)

    &-iframe
      transform-origin: top left
      background-color: $color-white
      position: relative

      .hide-controls &
        width: 100%!important
        height: 100%!important

    &-video
      width: 100%
      height: 100%

    &-error
      @extend .dwa-flex-center

      height: 100%
      color: $color-white
      flex-direction: column

      a
        color: $color-white
        text-decoration: underline
        font-weight: bold



  &-loader
    @extend .dwa-flex-center

    height: 100%
    background-color: $color-gray-2
    color: $color-white


  &-toolbar
    position: absolute
    top: 0
    right: 0
    width: 100%
    height: $topnav-height
    display: flex
    flex-direction: row-reverse
    background-color: lighten($color-black, 10%)
    transition: opacity ease-in-out 0.5s

    .hide-controls &
      display: none

    .history-opened &
      opacity: 1!important

    .fullscreen-activated &
      width: $publication-toolbar-width
      height: 100%
      flex-direction: column

      &.no-touch
        opacity: 0

        &:before
          content: ""
          position: absolute
          right: $publication-toolbar-width
          width: 50vw
          height: 100%

        &:hover
          opacity: 1


    &-item
      position: relative

      &-button
        @extend .dwa-hover-opacity-inverse

        height: 100%

        &.active,
        .active &
          opacity: 0.8

        &:disabled
          opacity: 0.2
          pointer-events: none

        .fullscreen-activated &
          height: auto


      &-icon
        color: $color-white
        display: inline-block
        width: 16px
        height: 16px
        margin: 15px


        .fullscreen-activated &
          width: 21px
          height: 21px


        .close &
          margin-left: 50px


        .history &
          width: 19px
          height: 19px


        .favorites &
          width: 21px
          height: 20px


        .toolbar &
          width: 44px
          margin: 15px 10px

          .fullscreen-activated &
            margin: 15px 7px


        .account-hub &
          width: 27px
          height: 22px


        .mail &
          width: auto
          font-size: 22px!important
          transform: translateY(-2px)


      &-submenu
        position: absolute
        z-index: 1
        top: $topnav-height
        right: 0
        width: 300px
        padding: 0 15px
        font-size: 12px
        text-align: left
        color: $color-white
        background-color: lighten($color-black, 10%)
        border-top: 1px solid lighten($color-black, 30%)

        .fullscreen-activated &
          top: 0
          right: $publication-toolbar-width
          border-right: 1px solid lighten($color-black, 30%)
          border-top: none


        &-item
          @extend .dwa-hover-opacity-inverse

          padding: 15px 0
          width: 100%
          box-sizing: border-box
          color: $color-white
          display: flex
          align-items: center
          border-bottom: 1px solid lighten($color-black, 20%)

          &:disabled
            opacity: 0.2
            pointer-events: none

          &:last-child
            border-bottom: none


        &-image
          display: block
          max-width: 40px
          margin-right: 10px
