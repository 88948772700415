@mixin icon-type($brands: false, $weight: 400) {
  $f: if($brands, 'Font Awesome\ 5 Brands', 'Font Awesome\ 5 Free');

  font-family: $f;
  font-weight: $weight;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@mixin icon-image($name, $before: true) {
  $u: map-get($icons, $name);
  $s: if($before==true, before, after);

  &:#{$s} {
    content: unquote("\"#{ $u }\"");
  }
}

$icons: (
  '500px': \f26e,
  'accessible-icon': \f368,
  'accusoft': \f369,
  'address-book': \f2b9,
  'address-card': \f2bb,
  'adjust': \f042,
  'adn': \f170,
  'adversal': \f36a,
  'affiliatetheme': \f36b,
  'algolia': \f36c,
  'align-center': \f037,
  'align-justify': \f039,
  'align-left': \f036,
  'align-right': \f038,
  'allergies': \f461,
  'amazon': \f270,
  'amazon-pay': \f42c,
  'ambulance': \f0f9,
  'american-sign-language-interpreting': \f2a3,
  'amilia': \f36d,
  'anchor': \f13d,
  'android': \f17b,
  'angellist': \f209,
  'angle-double-down': \f103,
  'angle-double-left': \f100,
  'angle-double-right': \f101,
  'angle-double-up': \f102,
  'angle-down': \f107,
  'angle-left': \f104,
  'angle-right': \f105,
  'angle-up': \f106,
  'angrycreative': \f36e,
  'angular': \f420,
  'app-store': \f36f,
  'app-store-ios': \f370,
  'apper': \f371,
  'apple': \f179,
  'apple-pay': \f415,
  'archive': \f187,
  'arrow-alt-circle-down': \f358,
  'arrow-alt-circle-left': \f359,
  'arrow-alt-circle-right': \f35a,
  'arrow-alt-circle-up': \f35b,
  'arrow-circle-down': \f0ab,
  'arrow-circle-left': \f0a8,
  'arrow-circle-right': \f0a9,
  'arrow-circle-up': \f0aa,
  'arrow-down': \f063,
  'arrow-left': \f060,
  'arrow-right': \f061,
  'arrow-up': \f062,
  'arrows-alt': \f0b2,
  'arrows-alt-h': \f337,
  'arrows-alt-v': \f338,
  'assistive-listening-systems': \f2a2,
  'asterisk': \f069,
  'asymmetrik': \f372,
  'at': \f1fa,
  'audible': \f373,
  'audio-description': \f29e,
  'autoprefixer': \f41c,
  'avianex': \f374,
  'aviato': \f421,
  'aws': \f375,
  'backward': \f04a,
  'balance-scale': \f24e,
  'ban': \f05e,
  'band-aid': \f462,
  'bandcamp': \f2d5,
  'barcode': \f02a,
  'bars': \f0c9,
  'baseball-ball': \f433,
  'basketball-ball': \f434,
  'bath': \f2cd,
  'battery-empty': \f244,
  'battery-full': \f240,
  'battery-half': \f242,
  'battery-quarter': \f243,
  'battery-three-quarters': \f241,
  'bed': \f236,
  'beer': \f0fc,
  'behance': \f1b4,
  'behance-square': \f1b5,
  'bell': \f0f3,
  'bell-slash': \f1f6,
  'bicycle': \f206,
  'bimobject': \f378,
  'binoculars': \f1e5,
  'birthday-cake': \f1fd,
  'bitbucket': \f171,
  'bitcoin': \f379,
  'bity': \f37a,
  'black-tie': \f27e,
  'blackberry': \f37b,
  'blind': \f29d,
  'blogger': \f37c,
  'blogger-b': \f37d,
  'bluetooth': \f293,
  'bluetooth-b': \f294,
  'bold': \f032,
  'bolt': \f0e7,
  'bomb': \f1e2,
  'book': \f02d,
  'bookmark': \f02e,
  'bowling-ball': \f436,
  'box': \f466,
  'box-open': \f49e,
  'boxes': \f468,
  'braille': \f2a1,
  'briefcase': \f0b1,
  'briefcase-medical': \f469,
  'btc': \f15a,
  'bug': \f188,
  'building': \f1ad,
  'bullhorn': \f0a1,
  'bullseye': \f140,
  'burn': \f46a,
  'buromobelexperte': \f37f,
  'bus': \f207,
  'buysellads': \f20d,
  'calculator': \f1ec,
  'calendar': \f133,
  'calendar-alt': \f073,
  'calendar-check': \f274,
  'calendar-minus': \f272,
  'calendar-plus': \f271,
  'calendar-times': \f273,
  'camera': \f030,
  'camera-retro': \f083,
  'capsules': \f46b,
  'car': \f1b9,
  'caret-down': \f0d7,
  'caret-left': \f0d9,
  'caret-right': \f0da,
  'caret-square-down': \f150,
  'caret-square-left': \f191,
  'caret-square-right': \f152,
  'caret-square-up': \f151,
  'caret-up': \f0d8,
  'cart-arrow-down': \f218,
  'cart-plus': \f217,
  'cc-amazon-pay': \f42d,
  'cc-amex': \f1f3,
  'cc-apple-pay': \f416,
  'cc-diners-club': \f24c,
  'cc-discover': \f1f2,
  'cc-jcb': \f24b,
  'cc-mastercard': \f1f1,
  'cc-paypal': \f1f4,
  'cc-stripe': \f1f5,
  'cc-visa': \f1f0,
  'centercode': \f380,
  'certificate': \f0a3,
  'chart-area': \f1fe,
  'chart-bar': \f080,
  'chart-line': \f201,
  'chart-pie': \f200,
  'check': \f00c,
  'check-circle': \f058,
  'check-square': \f14a,
  'chess': \f439,
  'chess-bishop': \f43a,
  'chess-board': \f43c,
  'chess-king': \f43f,
  'chess-knight': \f441,
  'chess-pawn': \f443,
  'chess-queen': \f445,
  'chess-rook': \f447,
  'chevron-circle-down': \f13a,
  'chevron-circle-left': \f137,
  'chevron-circle-right': \f138,
  'chevron-circle-up': \f139,
  'chevron-down': \f078,
  'chevron-left': \f053,
  'chevron-right': \f054,
  'chevron-up': \f077,
  'child': \f1ae,
  'chrome': \f268,
  'circle': \f111,
  'circle-notch': \f1ce,
  'clipboard': \f328,
  'clipboard-check': \f46c,
  'clipboard-list': \f46d,
  'clock': \f017,
  'clone': \f24d,
  'closed-captioning': \f20a,
  'cloud': \f0c2,
  'cloud-download-alt': \f381,
  'cloud-upload-alt': \f382,
  'cloudscale': \f383,
  'cloudsmith': \f384,
  'cloudversify': \f385,
  'code': \f121,
  'code-branch': \f126,
  'codepen': \f1cb,
  'codiepie': \f284,
  'coffee': \f0f4,
  'cog': \f013,
  'cogs': \f085,
  'columns': \f0db,
  'comment': \f075,
  'comment-alt': \f27a,
  'comment-dots': \f4ad,
  'comment-slash': \f4b3,
  'comments': \f086,
  'compass': \f14e,
  'compress': \f066,
  'connectdevelop': \f20e,
  'contao': \f26d,
  'copy': \f0c5,
  'copyright': \f1f9,
  'couch': \f4b8,
  'cpanel': \f388,
  'creative-commons': \f25e,
  'credit-card': \f09d,
  'crop': \f125,
  'crosshairs': \f05b,
  'css3': \f13c,
  'css3-alt': \f38b,
  'cube': \f1b2,
  'cubes': \f1b3,
  'cut': \f0c4,
  'cuttlefish': \f38c,
  'd-and-d': \f38d,
  'dashcube': \f210,
  'database': \f1c0,
  'deaf': \f2a4,
  'delicious': \f1a5,
  'deploydog': \f38e,
  'deskpro': \f38f,
  'desktop': \f108,
  'deviantart': \f1bd,
  'diagnoses': \f470,
  'digg': \f1a6,
  'digital-ocean': \f391,
  'discord': \f392,
  'discourse': \f393,
  'dna': \f471,
  'dochub': \f394,
  'docker': \f395,
  'dollar-sign': \f155,
  'dolly': \f472,
  'dolly-flatbed': \f474,
  'donate': \f4b9,
  'dot-circle': \f192,
  'dove': \f4ba,
  'download': \f019,
  'draft2digital': \f396,
  'dribbble': \f17d,
  'dribbble-square': \f397,
  'dropbox': \f16b,
  'drupal': \f1a9,
  'dyalog': \f399,
  'earlybirds': \f39a,
  'edge': \f282,
  'edit': \f044,
  'eject': \f052,
  'elementor': \f430,
  'ellipsis-h': \f141,
  'ellipsis-v': \f142,
  'ember': \f423,
  'empire': \f1d1,
  'envelope': \f0e0,
  'envelope-open': \f2b6,
  'envelope-square': \f199,
  'envira': \f299,
  'eraser': \f12d,
  'erlang': \f39d,
  'ethereum': \f42e,
  'etsy': \f2d7,
  'euro-sign': \f153,
  'exchange-alt': \f362,
  'exclamation': \f12a,
  'exclamation-circle': \f06a,
  'exclamation-triangle': \f071,
  'expand': \f065,
  'expand-arrows-alt': \f31e,
  'expeditedssl': \f23e,
  'external-link-alt': \f35d,
  'external-link-square-alt': \f360,
  'eye': \f06e,
  'eye-dropper': \f1fb,
  'eye-slash': \f070,
  'facebook': \f09a,
  'facebook-f': \f39e,
  'facebook-messenger': \f39f,
  'facebook-square': \f082,
  'fast-backward': \f049,
  'fast-forward': \f050,
  'fax': \f1ac,
  'female': \f182,
  'fighter-jet': \f0fb,
  'file': \f15b,
  'file-alt': \f15c,
  'file-archive': \f1c6,
  'file-audio': \f1c7,
  'file-code': \f1c9,
  'file-excel': \f1c3,
  'file-image': \f1c5,
  'file-medical': \f477,
  'file-medical-alt': \f478,
  'file-pdf': \f1c1,
  'file-powerpoint': \f1c4,
  'file-video': \f1c8,
  'file-word': \f1c2,
  'film': \f008,
  'filter': \f0b0,
  'fire': \f06d,
  'fire-extinguisher': \f134,
  'firefox': \f269,
  'first-aid': \f479,
  'first-order': \f2b0,
  'firstdraft': \f3a1,
  'flag': \f024,
  'flag-checkered': \f11e,
  'flask': \f0c3,
  'flickr': \f16e,
  'flipboard': \f44d,
  'fly': \f417,
  'folder': \f07b,
  'folder-open': \f07c,
  'font': \f031,
  'font-awesome': \f2b4,
  'font-awesome-alt': \f35c,
  'font-awesome-flag': \f425,
  'fonticons': \f280,
  'fonticons-fi': \f3a2,
  'football-ball': \f44e,
  'fort-awesome': \f286,
  'fort-awesome-alt': \f3a3,
  'forumbee': \f211,
  'forward': \f04e,
  'foursquare': \f180,
  'free-code-camp': \f2c5,
  'freebsd': \f3a4,
  'frown': \f119,
  'futbol': \f1e3,
  'gamepad': \f11b,
  'gavel': \f0e3,
  'gem': \f3a5,
  'genderless': \f22d,
  'get-pocket': \f265,
  'gg': \f260,
  'gg-circle': \f261,
  'gift': \f06b,
  'git': \f1d3,
  'git-square': \f1d2,
  'github': \f09b,
  'github-alt': \f113,
  'github-square': \f092,
  'gitkraken': \f3a6,
  'gitlab': \f296,
  'gitter': \f426,
  'glass-martini': \f000,
  'glide': \f2a5,
  'glide-g': \f2a6,
  'globe': \f0ac,
  'gofore': \f3a7,
  'golf-ball': \f450,
  'goodreads': \f3a8,
  'goodreads-g': \f3a9,
  'google': \f1a0,
  'google-drive': \f3aa,
  'google-play': \f3ab,
  'google-plus': \f2b3,
  'google-plus-g': \f0d5,
  'google-plus-square': \f0d4,
  'google-wallet': \f1ee,
  'graduation-cap': \f19d,
  'gratipay': \f184,
  'grav': \f2d6,
  'gripfire': \f3ac,
  'grunt': \f3ad,
  'gulp': \f3ae,
  'h-square': \f0fd,
  'hacker-news': \f1d4,
  'hacker-news-square': \f3af,
  'hand-holding': \f4bd,
  'hand-holding-heart': \f4be,
  'hand-holding-usd': \f4c0,
  'hand-lizard': \f258,
  'hand-paper': \f256,
  'hand-peace': \f25b,
  'hand-point-down': \f0a7,
  'hand-point-left': \f0a5,
  'hand-point-right': \f0a4,
  'hand-point-up': \f0a6,
  'hand-pointer': \f25a,
  'hand-rock': \f255,
  'hand-scissors': \f257,
  'hand-spock': \f259,
  'hands': \f4c2,
  'hands-helping': \f4c4,
  'handshake': \f2b5,
  'hashtag': \f292,
  'hdd': \f0a0,
  'heading': \f1dc,
  'headphones': \f025,
  'heart': \f004,
  'heartbeat': \f21e,
  'hips': \f452,
  'hire-a-helper': \f3b0,
  'history': \f1da,
  'hockey-puck': \f453,
  'home': \f015,
  'hooli': \f427,
  'hospital': \f0f8,
  'hospital-alt': \f47d,
  'hospital-symbol': \f47e,
  'hotjar': \f3b1,
  'hourglass': \f254,
  'hourglass-end': \f253,
  'hourglass-half': \f252,
  'hourglass-start': \f251,
  'houzz': \f27c,
  'html5': \f13b,
  'hubspot': \f3b2,
  'i-cursor': \f246,
  'id-badge': \f2c1,
  'id-card': \f2c2,
  'id-card-alt': \f47f,
  'image': \f03e,
  'images': \f302,
  'imdb': \f2d8,
  'inbox': \f01c,
  'indent': \f03c,
  'industry': \f275,
  'info': \f129,
  'info-circle': \f05a,
  'instagram': \f16d,
  'internet-explorer': \f26b,
  'ioxhost': \f208,
  'italic': \f033,
  'itunes': \f3b4,
  'itunes-note': \f3b5,
  'java': \f4e4,
  'jenkins': \f3b6,
  'joget': \f3b7,
  'joomla': \f1aa,
  'js': \f3b8,
  'js-square': \f3b9,
  'jsfiddle': \f1cc,
  'key': \f084,
  'keyboard': \f11c,
  'keycdn': \f3ba,
  'kickstarter': \f3bb,
  'kickstarter-k': \f3bc,
  'korvue': \f42f,
  'language': \f1ab,
  'laptop': \f109,
  'laravel': \f3bd,
  'lastfm': \f202,
  'lastfm-square': \f203,
  'leaf': \f06c,
  'leanpub': \f212,
  'lemon': \f094,
  'less': \f41d,
  'level-down-alt': \f3be,
  'level-up-alt': \f3bf,
  'life-ring': \f1cd,
  'lightbulb': \f0eb,
  'line': \f3c0,
  'link': \f0c1,
  'linkedin': \f08c,
  'linkedin-in': \f0e1,
  'linode': \f2b8,
  'linux': \f17c,
  'lira-sign': \f195,
  'list': \f03a,
  'list-alt': \f022,
  'list-ol': \f0cb,
  'list-ul': \f0ca,
  'location-arrow': \f124,
  'lock': \f023,
  'lock-open': \f3c1,
  'long-arrow-alt-down': \f309,
  'long-arrow-alt-left': \f30a,
  'long-arrow-alt-right': \f30b,
  'long-arrow-alt-up': \f30c,
  'low-vision': \f2a8,
  'lyft': \f3c3,
  'magento': \f3c4,
  'magic': \f0d0,
  'magnet': \f076,
  'male': \f183,
  'map': \f279,
  'map-marker': \f041,
  'map-marker-alt': \f3c5,
  'map-pin': \f276,
  'map-signs': \f277,
  'mars': \f222,
  'mars-double': \f227,
  'mars-stroke': \f229,
  'mars-stroke-h': \f22b,
  'mars-stroke-v': \f22a,
  'maxcdn': \f136,
  'medapps': \f3c6,
  'medium': \f23a,
  'medium-m': \f3c7,
  'medkit': \f0fa,
  'medrt': \f3c8,
  'meetup': \f2e0,
  'meh': \f11a,
  'mercury': \f223,
  'microchip': \f2db,
  'microphone': \f130,
  'microphone-slash': \f131,
  'microsoft': \f3ca,
  'minus': \f068,
  'minus-circle': \f056,
  'minus-square': \f146,
  'mix': \f3cb,
  'mixcloud': \f289,
  'mizuni': \f3cc,
  'mobile': \f10b,
  'mobile-alt': \f3cd,
  'modx': \f285,
  'monero': \f3d0,
  'money-bill-alt': \f3d1,
  'moon': \f186,
  'motorcycle': \f21c,
  'mouse-pointer': \f245,
  'music': \f001,
  'napster': \f3d2,
  'neuter': \f22c,
  'newspaper': \f1ea,
  'nintendo-switch': \f418,
  'node': \f419,
  'node-js': \f3d3,
  'notes-medical': \f481,
  'npm': \f3d4,
  'ns8': \f3d5,
  'nutritionix': \f3d6,
  'object-group': \f247,
  'object-ungroup': \f248,
  'odnoklassniki': \f263,
  'odnoklassniki-square': \f264,
  'opencart': \f23d,
  'openid': \f19b,
  'opera': \f26a,
  'optin-monster': \f23c,
  'osi': \f41a,
  'outdent': \f03b,
  'page4': \f3d7,
  'pagelines': \f18c,
  'paint-brush': \f1fc,
  'palfed': \f3d8,
  'pallet': \f482,
  'paper-plane': \f1d8,
  'paperclip': \f0c6,
  'parachute-box': \f4cd,
  'paragraph': \f1dd,
  'paste': \f0ea,
  'patreon': \f3d9,
  'pause': \f04c,
  'pause-circle': \f28b,
  'paw': \f1b0,
  'paypal': \f1ed,
  'pen-square': \f14b,
  'pencil-alt': \f303,
  'people-carry': \f4ce,
  'percent': \f295,
  'periscope': \f3da,
  'phabricator': \f3db,
  'phoenix-framework': \f3dc,
  'phone': \f095,
  'phone-slash': \f3dd,
  'phone-square': \f098,
  'phone-volume': \f2a0,
  'php': \f457,
  'pied-piper': \f2ae,
  'pied-piper-alt': \f1a8,
  'pied-piper-hat': \f4e5,
  'pied-piper-pp': \f1a7,
  'piggy-bank': \f4d3,
  'pills': \f484,
  'pinterest': \f0d2,
  'pinterest-p': \f231,
  'pinterest-square': \f0d3,
  'plane': \f072,
  'play': \f04b,
  'play-circle': \f144,
  'playstation': \f3df,
  'plug': \f1e6,
  'plus': \f067,
  'plus-circle': \f055,
  'plus-square': \f0fe,
  'podcast': \f2ce,
  'poo': \f2fe,
  'pound-sign': \f154,
  'power-off': \f011,
  'prescription-bottle': \f485,
  'prescription-bottle-alt': \f486,
  'print': \f02f,
  'procedures': \f487,
  'product-hunt': \f288,
  'pushed': \f3e1,
  'puzzle-piece': \f12e,
  'python': \f3e2,
  'qq': \f1d6,
  'qrcode': \f029,
  'question': \f128,
  'question-circle': \f059,
  'quidditch': \f458,
  'quinscape': \f459,
  'quora': \f2c4,
  'quote-left': \f10d,
  'quote-right': \f10e,
  'random': \f074,
  'ravelry': \f2d9,
  'react': \f41b,
  'readme': \f4d5,
  'rebel': \f1d0,
  'recycle': \f1b8,
  'red-river': \f3e3,
  'reddit': \f1a1,
  'reddit-alien': \f281,
  'reddit-square': \f1a2,
  'redo': \f01e,
  'redo-alt': \f2f9,
  'registered': \f25d,
  'rendact': \f3e4,
  'renren': \f18b,
  'reply': \f3e5,
  'reply-all': \f122,
  'replyd': \f3e6,
  'resolving': \f3e7,
  'retweet': \f079,
  'ribbon': \f4d6,
  'road': \f018,
  'rocket': \f135,
  'rocketchat': \f3e8,
  'rockrms': \f3e9,
  'rss': \f09e,
  'rss-square': \f143,
  'ruble-sign': \f158,
  'rupee-sign': \f156,
  'safari': \f267,
  'sass': \f41e,
  'save': \f0c7,
  'schlix': \f3ea,
  'scribd': \f28a,
  'search': \f002,
  'search-minus': \f010,
  'search-plus': \f00e,
  'searchengin': \f3eb,
  'seedling': \f4d8,
  'sellcast': \f2da,
  'sellsy': \f213,
  'server': \f233,
  'servicestack': \f3ec,
  'share': \f064,
  'share-alt': \f1e0,
  'share-alt-square': \f1e1,
  'share-square': \f14d,
  'shekel-sign': \f20b,
  'shield-alt': \f3ed,
  'ship': \f21a,
  'shipping-fast': \f48b,
  'shirtsinbulk': \f214,
  'shopping-bag': \f290,
  'shopping-basket': \f291,
  'shopping-cart': \f07a,
  'shower': \f2cc,
  'sign': \f4d9,
  'sign-in-alt': \f2f6,
  'sign-language': \f2a7,
  'sign-out-alt': \f2f5,
  'signal': \f012,
  'simplybuilt': \f215,
  'sistrix': \f3ee,
  'sitemap': \f0e8,
  'skyatlas': \f216,
  'skype': \f17e,
  'slack': \f198,
  'slack-hash': \f3ef,
  'sliders-h': \f1de,
  'slideshare': \f1e7,
  'smile': \f118,
  'smoking': \f48d,
  'snapchat': \f2ab,
  'snapchat-ghost': \f2ac,
  'snapchat-square': \f2ad,
  'snowflake': \f2dc,
  'sort': \f0dc,
  'sort-alpha-down': \f15d,
  'sort-alpha-up': \f15e,
  'sort-amount-down': \f160,
  'sort-amount-up': \f161,
  'sort-down': \f0dd,
  'sort-numeric-down': \f162,
  'sort-numeric-up': \f163,
  'sort-up': \f0de,
  'soundcloud': \f1be,
  'space-shuttle': \f197,
  'speakap': \f3f3,
  'spinner': \f110,
  'spotify': \f1bc,
  'square': \f0c8,
  'square-full': \f45c,
  'stack-exchange': \f18d,
  'stack-overflow': \f16c,
  'star': \f005,
  'star-half': \f089,
  'staylinked': \f3f5,
  'steam': \f1b6,
  'steam-square': \f1b7,
  'steam-symbol': \f3f6,
  'step-backward': \f048,
  'step-forward': \f051,
  'stethoscope': \f0f1,
  'sticker-mule': \f3f7,
  'sticky-note': \f249,
  'stop': \f04d,
  'stop-circle': \f28d,
  'stopwatch': \f2f2,
  'strava': \f428,
  'street-view': \f21d,
  'strikethrough': \f0cc,
  'stripe': \f429,
  'stripe-s': \f42a,
  'studiovinari': \f3f8,
  'stumbleupon': \f1a4,
  'stumbleupon-circle': \f1a3,
  'subscript': \f12c,
  'subway': \f239,
  'suitcase': \f0f2,
  'sun': \f185,
  'superpowers': \f2dd,
  'superscript': \f12b,
  'supple': \f3f9,
  'sync': \f021,
  'sync-alt': \f2f1,
  'syringe': \f48e,
  'table': \f0ce,
  'table-tennis': \f45d,
  'tablet': \f10a,
  'tablet-alt': \f3fa,
  'tablets': \f490,
  'tachometer-alt': \f3fd,
  'tag': \f02b,
  'tags': \f02c,
  'tape': \f4db,
  'tasks': \f0ae,
  'taxi': \f1ba,
  'telegram': \f2c6,
  'telegram-plane': \f3fe,
  'tencent-weibo': \f1d5,
  'terminal': \f120,
  'text-height': \f034,
  'text-width': \f035,
  'th': \f00a,
  'th-large': \f009,
  'th-list': \f00b,
  'themeisle': \f2b2,
  'thermometer': \f491,
  'thermometer-empty': \f2cb,
  'thermometer-full': \f2c7,
  'thermometer-half': \f2c9,
  'thermometer-quarter': \f2ca,
  'thermometer-three-quarters': \f2c8,
  'thumbs-down': \f165,
  'thumbs-up': \f164,
  'thumbtack': \f08d,
  'ticket-alt': \f3ff,
  'times': \f00d,
  'times-circle': \f057,
  'tint': \f043,
  'toggle-off': \f204,
  'toggle-on': \f205,
  'trademark': \f25c,
  'train': \f238,
  'transgender': \f224,
  'transgender-alt': \f225,
  'trash': \f1f8,
  'trash-alt': \f2ed,
  'tree': \f1bb,
  'trello': \f181,
  'tripadvisor': \f262,
  'trophy': \f091,
  'truck': \f0d1,
  'truck-loading': \f4de,
  'truck-moving': \f4df,
  'tty': \f1e4,
  'tumblr': \f173,
  'tumblr-square': \f174,
  'tv': \f26c,
  'twitch': \f1e8,
  'twitter': \f099,
  'twitter-square': \f081,
  'typo3': \f42b,
  'uber': \f402,
  'uikit': \f403,
  'umbrella': \f0e9,
  'underline': \f0cd,
  'undo': \f0e2,
  'undo-alt': \f2ea,
  'uniregistry': \f404,
  'universal-access': \f29a,
  'university': \f19c,
  'unlink': \f127,
  'unlock': \f09c,
  'unlock-alt': \f13e,
  'untappd': \f405,
  'upload': \f093,
  'usb': \f287,
  'user': \f007,
  'user-circle': \f2bd,
  'user-md': \f0f0,
  'user-plus': \f234,
  'user-secret': \f21b,
  'user-times': \f235,
  'users': \f0c0,
  'ussunnah': \f407,
  'utensil-spoon': \f2e5,
  'utensils': \f2e7,
  'vaadin': \f408,
  'venus': \f221,
  'venus-double': \f226,
  'venus-mars': \f228,
  'viacoin': \f237,
  'viadeo': \f2a9,
  'viadeo-square': \f2aa,
  'vial': \f492,
  'vials': \f493,
  'viber': \f409,
  'video': \f03d,
  'video-slash': \f4e2,
  'vimeo': \f40a,
  'vimeo-square': \f194,
  'vimeo-v': \f27d,
  'vine': \f1ca,
  'vk': \f189,
  'vnv': \f40b,
  'volleyball-ball': \f45f,
  'volume-down': \f027,
  'volume-off': \f026,
  'volume-up': \f028,
  'vuejs': \f41f,
  'warehouse': \f494,
  'weibo': \f18a,
  'weight': \f496,
  'weixin': \f1d7,
  'whatsapp': \f232,
  'whatsapp-square': \f40c,
  'wheelchair': \f193,
  'whmcs': \f40d,
  'wifi': \f1eb,
  'wikipedia-w': \f266,
  'window-close': \f410,
  'window-maximize': \f2d0,
  'window-minimize': \f2d1,
  'window-restore': \f2d2,
  'windows': \f17a,
  'wine-glass': \f4e3,
  'won-sign': \f159,
  'wordpress': \f19a,
  'wordpress-simple': \f411,
  'wpbeginner': \f297,
  'wpexplorer': \f2de,
  'wpforms': \f298,
  'wrench': \f0ad,
  'x-ray': \f497,
  'xbox': \f412,
  'xing': \f168,
  'xing-square': \f169,
  'y-combinator': \f23b,
  'yahoo': \f19e,
  'yandex': \f413,
  'yandex-international': \f414,
  'yelp': \f1e9,
  'yen-sign': \f157,
  'yoast': \f2b1,
  'youtube': \f167,
  'youtube-square': \f431
);
