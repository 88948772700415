// ARTICLE
// Vars

$article-share-icon: 16px

//

.dwa-article
  @extend .dwa-flex-column

  &-image
    max-width: 100%
    margin-bottom: 45px
    border-radius: $border-radius

  &-title
    @extend .dwa-title-big

    margin-bottom: 15px

  &-subtitle
    @extend .dwa-subtitle

    margin-bottom: 20px

  &-entry
    @extend .dwa-text

    p
      margin-bottom: 20px

  &-footer
    display: flex

  &-share
    display: flex
    align-items: center

    &-title
      @extend .dwa-text

      margin-right: 4px
      font-weight: $font-bold

    &-link
      @extend .dwa-hover-color-green
      @extend .dwa-flex-center

      margin-left: 8px
      border: 1px solid $color-gray-6
      color: $color-gray-6

    &-icon
      width: $article-share-icon
      height: $article-share-icon
      margin: 5px
