.dwa-login
  background-image: url('/assets/images/pages/login/background.jpg')
  background-repeat: no-repeat
  background-size: cover
  height: 100vh
  align-items: center
  justify-content: center
  display: flex
  visibility: hidden

  &.visible
    visibility: visible


  &-container
    width: 350px
    text-align: center

  &-subtitle
    display: block
    margin-top: 20px
    padding: 0 30px
    font-size: 13px

  &-form
    display: flex
    flex-direction: column
    margin-top: 30px

  &-field
    width: 350px


  &-option
    height: 110px

    &-item
      position: absolute


  &-icon
    &-username
      +icon-image('user')

    &-password
      +icon-image('lock')


  &-bottom
    margin-top: 15px
