@charset "UTF-8";
@import '~reset-css/reset.css';
a {
  text-decoration: none;
}

button,
input,
textarea {
  padding: 0;
  background: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

input,
textarea {
  overflow: hidden;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
  touch-action: manipulation;
}
html.cdk-global-scrollblock {
  overflow-y: hidden;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dwa-flex-column, .dwa-faq-posts-list, .dwa-help-sidebar-list, .dwa-article, .dwa-kiosks-list, .dwa-card-thumb-menu-list, .dwa-card-thumb-menu, .dwa-card-thumb-link, .dwa-card-thumb-content, .dwa-content-main, .dwa-sidebar-sublist-2-item, .dwa-sidebar-sublist-1-item, .dwa-sidebar-item-link, .dwa-sidebar-item, .dwa-sidebar, .dwa-topnav-menu-link-content {
  display: flex;
  flex-direction: column;
}

.dwa-flex-center, .dwa-article-share-link, .dwa-publication-dialog-loader, .dwa-publication-dialog-content-error, .dwa-slidethumbs-pagination-button, .dwa-card-thumb-button-more, .dwa-sidebar-sublist-1-loader, .dwa-topnav-menu-item-notification, .dwa-loader-component {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dwa-absolute-full, .dwa-slide-item-background, .dwa-slide-item, .dwa-slide-list, .dwa-card-thumb-menu-background, .dwa-card-thumb-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dwa-hover-opacity, .dwa-confirm-dialog-button, .dwa-slidethumbs-title-link, .dwa-card-thumb-menu-item-button, .dwa-card-thumb-link, .dwa-card-thumb-item-button, .dwa-sidebar-tree-link, .dwa-sidebar-sublist-2-link, .dwa-sidebar-sublist-1-link, .dwa-sidebar-logo, .dwa-topnav-menu-item-link, .dwa-topnav-toggle, .dwa-terms-button {
  transition: opacity 0.2s;
}
.dwa-hover-opacity:hover, .dwa-confirm-dialog-button:hover, .dwa-slidethumbs-title-link:hover, .dwa-card-thumb-menu-item-button:hover, .dwa-card-thumb-link:hover, .dwa-card-thumb-item-button:hover, .dwa-sidebar-tree-link:hover, .dwa-sidebar-sublist-2-link:hover, .dwa-sidebar-sublist-1-link:hover, .dwa-sidebar-logo:hover, .dwa-topnav-menu-item-link:hover, .dwa-topnav-toggle:hover, .dwa-terms-button:hover {
  opacity: 0.7;
}
.dwa-hover-opacity-inverse, .dwa-publication-dialog-toolbar-item-submenu-item, .dwa-publication-dialog-toolbar-item-button, .dwa-topnav-sections-link {
  opacity: 0.5;
  transition: opacity 0.2s;
}
.dwa-hover-opacity-inverse:hover, .dwa-publication-dialog-toolbar-item-submenu-item:hover, .dwa-publication-dialog-toolbar-item-button:hover, .dwa-topnav-sections-link:hover {
  opacity: 1 !important;
}
.dwa-hover-color-green, .dwa-faq-posts-item-link, .dwa-help-sidebar-item-link, .dwa-article-share-link, .dwa-slide-item-link {
  transition: color 0.2s;
}
.dwa-hover-color-green:hover, .dwa-faq-posts-item-link:hover, .dwa-help-sidebar-item-link:hover, .dwa-article-share-link:hover, .dwa-slide-item-link:hover {
  color: #00a6bf !important;
}
.dwa-hover-color-black, .dwa-breadcrumb-link {
  transition: color 0.2s;
}
.dwa-hover-color-black:hover, .dwa-breadcrumb-link:hover {
  color: #000000 !important;
}
.dwa-hover-color-white, .dwa-sidebar-item-link {
  transition: color 0.2s;
}
.dwa-hover-color-white:hover, .dwa-sidebar-item-link:hover {
  color: #ffffff !important;
}

.dwa-show-active, .dwa-slide-item-content, .dwa-slide-item-background {
  animation: fadeOut 0.4s linear 0s forwards;
}
.active .dwa-show-active, .active .dwa-slide-item-content, .active .dwa-slide-item-background {
  animation: fadeIn 0.4s linear 0s forwards;
}

.input-public .mat-checkbox-checked.mat-accent .mat-checkbox-background, .input-public .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #00a6bf;
}

.mr-10 {
  margin-right: 10px;
}

.flex-4 {
  flex: 4;
}

.flex-1 {
  flex: 1;
}

.full-width {
  width: 100%;
}

.mr-10 {
  margin-right: 10px;
}

html {
  font-size: 14px;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  color: #231f20;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

strong {
  font-weight: 800;
}

.dwa-text-big, .dwa-faq-posts-item-text, .dwa-help-sidebar-item-text, .dwa-session-display-text, .dwa-session-display-time {
  font-size: 1.15rem;
  line-height: 1.6;
}

.dwa-text, .dwa-help-search-input, .dwa-article-share-title, .dwa-article-entry, .dwa-confirm-dialog-button, .dwa-news-item-text, .dwa-publications-item-text, .dwa-publications-item-category, .dwa-publications-item-title {
  font-size: 1rem;
  line-height: 1.6;
}

.dwa-text-small, .dwa-confirm-dialog-subtitle, .dwa-news-item-title, .dwa-publications-item-date, .dwa-card-thumb-text {
  font-size: 0.9rem;
  line-height: 1.6;
}

.dwa-text-tiny, .dwa-card-thumb-menu-item-button {
  font-size: 0.8rem;
  line-height: 1.6;
}

.dwa-title-big, .dwa-help-search-title, .dwa-article-title, .dwa-slide-item-title {
  font-size: 2.7rem;
  line-height: 1.3;
  font-weight: 400;
}

.dwa-title, .dwa-content-title {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 400;
}

.dwa-title-small, .dwa-faq-posts-title, .dwa-help-sidebar-title {
  font-size: 1.65rem;
  line-height: 1.3;
  font-weight: 400;
}

.dwa-subtitle, .dwa-article-subtitle, .dwa-confirm-dialog-title, .dwa-slide-item-subtitle, .dwa-card-style-title, .dwa-user-profile-title, .dwa-share-dialog-title, .dwa-slidethumbs-title {
  font-size: 1.45rem;
  line-height: 1.3;
  font-weight: 400;
}

.truncate-text, .dwa-content-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dwa-icon-font {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.dwa-icon-brand {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.dwa-btn {
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.dwa-btn.default {
  background-color: #00a6bf;
  color: #ffffff;
}

.dwa-loader {
  max-width: none !important;
}
.dwa-loader .mat-dialog-container {
  padding: 0;
  box-shadow: none;
}
.dwa-loader-backdrop {
  background-color: rgba(255, 255, 255, 0.8);
}
.dwa-loader-component {
  height: 100%;
}
.dwa-loader-title {
  font-size: 20px;
}
.dwa-loader-text {
  font-size: 20px;
  margin-left: 40px;
}
.dwa-loader-text-number {
  font-weight: bold;
}

.dwa-terms .mat-dialog-container {
  padding: 0;
}
.dwa-terms-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
.dwa-terms-component {
  width: 75vw;
  max-width: 1000px;
  text-align: center;
  display: block;
  overflow: hidden;
}
.dwa-terms-title {
  font-size: 20px;
  margin: 20px;
  color: #00a6bf;
}
.dwa-terms-content {
  height: 60vh;
  overflow-y: scroll;
  text-align: left;
  padding: 0 20px;
}
.dwa-terms-button {
  padding: 20px;
  margin: 20px;
  font-size: 20px;
  color: #ffffff;
  background-color: #00a6bf;
}
.dwa-terms-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dwa-version {
  position: fixed;
  pointer-events: none;
  right: 0;
  bottom: 0;
  padding: 5px;
  font-size: 10px;
}
@media only screen and (max-width: 1024px) {
  .dwa-version {
    position: relative;
    text-align: right;
    margin-top: -25px;
  }
}

.dwa-card-style, .dwa-user-profile .mat-dialog-container, .dwa-share-dialog .mat-dialog-container, .dwa-slidethumbs, .dwa-terms .mat-dialog-container {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
}
.dwa-card-style-header, .dwa-user-profile-header, .dwa-share-dialog-header, .dwa-slidethumbs-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 26px 35px;
  border-bottom: 1px solid #eaeaea;
}
.dwa-margin-bottom, .dwa-kiosks-item, .dwa-dashboard .dwa-slidethumbs,
.dwa-dashboard .dwa-slide {
  margin-bottom: 30px;
}

.dwa-topnav {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: 54px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: #e5e6e8;
  border-bottom: 1px solid #eaeaea;
  transition: margin-left 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@media only screen and (max-width: 1439px) {
  .dwa-topnav {
    height: 108px;
    display: block;
  }
}
.sidebar-opened .dwa-topnav {
  margin-left: 200px;
}
.dwa-topnav-toggle {
  border: 0;
  outline: 0;
  padding: 10px 20px;
  background-color: transparent;
  cursor: pointer;
  height: 54px;
  color: #00a6bf;
  background-color: #e5e6e8;
}
.dwa-topnav-toggle-icon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 20px;
}
.dwa-topnav-toggle-icon:before {
  content: "";
}
.dwa-topnav-start {
  display: flex;
  flex: 1;
  height: 54px;
  max-width: 600px;
  align-items: center;
}
@media only screen and (max-width: 1439px) {
  .dwa-topnav-start {
    max-width: none;
  }
}
.dwa-topnav-search {
  border: none;
  border-radius: 16px;
  background-color: #f7f7f7;
  font-size: 12px;
  padding: 10px 20px;
  height: 28px;
  margin-right: 20px;
  transition: background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dwa-topnav-search:active, .dwa-topnav-search:focus-within {
  background-color: #dedede;
  outline: none;
}
.dwa-topnav-sections {
  display: flex;
  flex: 1;
  height: 54px;
  border-right: 1px solid #eaeaea;
  background-color: #e5e6e8;
}
.dwa-topnav-sections-link {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  font-size: 12px;
  border-left: 1px solid #efefef;
  color: #000000;
  text-align: center;
}
.dwa-topnav-sections-link.active {
  opacity: 1;
  pointer-events: none;
}
.dwa-topnav-menu {
  display: flex;
  height: 54px;
}
.dwa-topnav-menu .mat-slide-toggle {
  height: 100%;
}
.dwa-topnav-menu-item {
  flex: 1;
  text-align: center;
  border-right: 1px solid #efefef;
  background-color: #e5e6e8;
}
@media only screen and (max-width: 1439px) {
  .dwa-topnav-menu-item {
    border-bottom: 1px solid #efefef;
  }
}
.dwa-topnav-menu-item:first-child {
  border-left: 1px solid #efefef;
}
.dwa-topnav-menu-item.user {
  border-right: none;
  border-left: 1px solid #efefef;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 1439px) {
  .dwa-topnav-menu-item.user {
    margin-left: auto;
  }
}
.dwa-topnav-menu-item.custom-button {
  background-color: #e5e6e8;
}
@media only screen and (max-width: 1439px) {
  .dwa-topnav-menu-item.custom-button {
    flex-grow: 0.75;
  }
}
.dwa-topnav-menu-item-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: auto;
  height: 100%;
  color: #000000;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .dwa-topnav-menu-item-link {
    padding: 0 20px;
  }
}
.dwa-topnav-menu-item-link .rotate {
  transition: 0.4s transform ease-in-out;
}
.dwa-topnav-menu-item-link .green {
  color: #00a6bf;
  text-align: center;
}
.dwa-topnav-menu-item-link:hover .rotate {
  transform: rotate(90deg);
}
.dwa-topnav-menu-item-image {
  max-width: 30px;
}
@media only screen and (max-width: 767px) {
  .dwa-topnav-menu-item-image {
    max-width: 20px;
  }
}
.dwa-topnav-menu-item-icon {
  width: 20px;
  height: 20px;
}
.dwa-topnav-menu-item-icon.icon-mail {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 21px;
  margin-top: 2px;
}
.dwa-topnav-menu-item-icon.icon-mail:before {
  content: "";
}
.dwa-topnav-menu-item-icon.icon-shared {
  width: 26px;
  height: 26px;
}
.dwa-topnav-menu-item-notification {
  position: absolute;
  top: 35%;
  right: 20%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 800;
  text-align: center;
  background: #00a6bf;
  border-radius: 100%;
}
.dwa-topnav-menu-item-reload {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 20px;
}
.dwa-topnav-menu-item-reload:before {
  content: "";
}
.dwa-topnav-menu-item-search {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 20px;
}
.dwa-topnav-menu-item-search:before {
  content: "";
}
.dwa-topnav-menu-item-lms {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 20px;
}
.dwa-topnav-menu-item-lms:before {
  content: "";
}
.dwa-topnav-menu-link-content {
  padding: 0 14px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .dwa-topnav-menu-link-content {
    display: none;
  }
}
.dwa-topnav-menu-link-title {
  font-size: 0.85rem;
}
.dwa-topnav-menu-link-subtitle {
  font-weight: 800;
}
.dwa-topnav-menu-link-email {
  min-width: 170px;
  font-size: 1.1em;
}
.dwa-topnav-menu-tooltip {
  position: absolute;
  top: 65px;
  padding: 5px 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
}
.dwa-topnav-menu-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: 30px;
  margin-left: -5px;
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.dwa-topnav-menu-avatar {
  width: 44px;
  height: 44px;
  background-color: #00a6bf;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 100%;
  overflow: hidden;
}
.dwa-topnav-menu-submenu {
  position: absolute;
  top: 57px;
  right: 10px;
  width: 320px;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
}
.dwa-topnav-menu-submenu:after {
  content: "";
  position: absolute;
  top: -5px;
  right: 30px;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-right: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
  transform: rotate(-45deg);
}
.dwa-topnav-menu-submenu-title {
  padding: 15px 20px;
  font-size: 1.3em;
  display: flex;
  align-items: center;
}
.dwa-topnav-menu-submenu-title-icon {
  margin-left: 5px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.dwa-topnav-menu-submenu-title-icon:before {
  content: "";
}
.dwa-topnav-menu-submenu-title-button {
  margin-left: auto;
  text-decoration: underline;
}
.dwa-topnav-menu-submenu-section {
  padding: 15px 20px;
  border-top: 1px solid #f7f7f7;
  font-size: 0.95em;
}
.dwa-topnav-menu-submenu-section .section-item {
  display: block;
  margin-top: 2px;
}
.dwa-topnav-menu-submenu-section .section-link:hover {
  text-decoration: underline;
}
.dwa-topnav-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.05);
}

.dwa-sidebar {
  position: fixed;
  top: 0;
  left: -200px;
  z-index: 20;
  width: 200px;
  height: 100%;
  background: #04707f;
  transition: left 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.sidebar-opened .dwa-sidebar {
  left: 0;
}
.dwa-sidebar-logo {
  position: fixed;
  z-index: 1;
  width: 200px;
  height: 54px;
  background: #00a6bf;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dwa-sidebar-logo-image {
  max-height: 40px;
}
.dwa-sidebar-list {
  margin-top: 54px;
}
.dwa-sidebar-item {
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dwa-sidebar-item-link {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: #00a6bf;
  font-size: 14px;
  text-align: center;
}
.active .dwa-sidebar-item-link {
  color: #ffffff;
}
.dwa-sidebar-item-link-background {
  background-color: #04707f;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dwa-sidebar-item-link-background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.active.change-on-active .dwa-sidebar-item-link-background:after {
  opacity: 1;
}
.dwa-sidebar-item-link-arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  border: 1px solid #00a6bf;
  bottom: -9px;
  left: 50%;
  background-color: #04707f;
  transform: rotateZ(45deg) translateX(-50%);
}
.dwa-sidebar-item-link-arrow:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.active.change-on-active .dwa-sidebar-item-link-arrow:before {
  opacity: 1;
}
.dwa-sidebar-item-icon {
  height: 30px;
  position: relative;
  z-index: 2;
}
.dwa-sidebar-item-icon-arrow {
  font-size: 14px;
}
.dwa-sidebar-item-text {
  position: relative;
  z-index: 2;
  font-size: 14px;
  margin: 10px 0 12px;
}
.dwa-sidebar-sublist-1 {
  overflow-y: scroll;
  margin-right: -17px;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #00a6bf;
}
.dwa-sidebar-sublist-1::-webkit-scrollbar {
  width: 17px;
}
.open-sub-1 .dwa-sidebar-sublist-1 {
  display: block;
}
.dwa-sidebar-sublist-1-loader {
  color: #ffffff;
  height: 65px;
  font-size: 11px;
}
.dwa-sidebar-sublist-1-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}
.dwa-sidebar-sublist-1-item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  color: red;
}
.dwa-sidebar-sublist-1-item:last-child {
  border: 0;
}
.dwa-sidebar-sublist-1-image {
  min-width: 25px;
  max-width: 25px;
  margin-right: 10px;
}
.dwa-sidebar-sublist-1-link {
  padding: 22px 15px 20px;
  color: #00a6bf;
  display: flex;
  align-items: center;
}
.dwa-sidebar-sublist-1-link.active {
  color: #ffffff;
  pointer-events: none;
}
.dwa-sidebar-sublist-2 {
  display: none;
}
.open-sub-2 .dwa-sidebar-sublist-2 {
  display: block;
}
.dwa-sidebar-sublist-2-image {
  min-width: 18.75px;
  max-width: 18.75px;
  margin-right: 10px;
}
.dwa-sidebar-sublist-2-link {
  padding: 14px 20px 14px 20px;
  display: flex;
  align-items: center;
  font-size: 0.92em;
}
.dwa-sidebar-sublist-2-link.active {
  text-decoration: underline;
}
.dwa-sidebar-tree {
  margin-left: 15px;
  margin-bottom: 20px;
}
.dwa-sidebar-tree-list {
  margin: 0 5px 10px 20px;
  font-size: 0.9em;
}
.dwa-sidebar-tree-link {
  font-style: italic;
  position: relative;
  display: block;
}
.dwa-sidebar-tree-link:before {
  content: "•";
  font-weight: bold;
  display: inline-block;
  position: absolute;
  width: 5px;
  left: -8px;
}
.dwa-sidebar-tree-link.active {
  text-decoration: underline;
}

.dashboard-icon:before {
  content: "";
}

.kiosks-icon:before {
  content: "";
}

.preparekiosks-icon:before {
  content: "";
}

.favorite-icon:before {
  content: "";
}

.arrow-down-icon:before {
  content: "";
}

.dwa-content {
  background: #f4f4f6;
  padding: 74px 0;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  transition: margin-left 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@media only screen and (max-width: 1439px) {
  .dwa-content {
    padding-top: 128px;
  }
}
.sidebar-opened .dwa-content {
  margin-left: 200px;
}
@media only screen and (max-width: 1023px) {
  .sidebar-opened .dwa-content {
    margin-left: 0;
  }
}
.dwa-content-main {
  left: 0;
  width: calc(100vw - 250px);
  margin: auto;
  max-width: 1320px;
}
@media only screen and (max-width: 1023px) {
  .dwa-content-main {
    width: 95vw;
  }
}
.sidebar-opened .dwa-content-main {
  left: 200px;
}
@media only screen and (max-width: 1023px) {
  .sidebar-opened .dwa-content-main {
    left: 0;
  }
}
.dwa-content-header {
  margin-bottom: 20px;
}
.dwa-content-title {
  display: flex;
  align-items: center;
  font-weight: 800;
}
.dwa-content-title-wrapper {
  min-height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dwa-content-title-icon {
  margin: 10px 20px 10px 0;
}
.dwa-content-title-item {
  align-items: flex-end;
  display: flex;
}

.dwa-field {
  position: relative;
  display: block;
  min-width: 200px;
  margin-bottom: 5px;
}
.dwa-field-label {
  position: absolute;
  top: 11px;
  left: 10px;
  color: transparent;
  opacity: 0.5;
}
.dwa-field-label .dwa-icon-font,
.dwa-field-label .dwa-icon-brand {
  color: #ffffff;
  background-color: #04707f;
  font-size: 12px;
  font-weight: 900;
  padding: 8px;
  border-radius: 50%;
}
.dwa-field-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  color: #231f20;
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  padding-top: 17px;
  padding-right: 17px;
  padding-bottom: 17px;
  padding-left: 47px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
}
.dwa-field-input.dropdown {
  text-indent: 47px;
  height: 50px;
}
.dwa-field-input.textarea {
  height: 100px;
}
.dwa-field-input:focus {
  outline: none;
  background-color: #f7f7f7;
}
.dwa-field-input:placeholder {
  color: rgba(35, 31, 32, 0.5);
}

.dwa-button, .dwa-user-profile-footer-button, .dwa-share-dialog-footer-button, .dwa-share-dialog-list-button, .dwa-share-dialog-form-action {
  display: block;
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  color: #ffffff;
  background-color: #00a6bf;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  padding: 17px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.dwa-button:disabled, .dwa-user-profile-footer-button:disabled, .dwa-share-dialog-footer-button:disabled, .dwa-share-dialog-list-button:disabled, .dwa-share-dialog-form-action:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.dwa-button:before, .dwa-user-profile-footer-button:before, .dwa-share-dialog-footer-button:before, .dwa-share-dialog-list-button:before, .dwa-share-dialog-form-action:before {
  position: relative;
  z-index: 1;
}
.dwa-button:after, .dwa-user-profile-footer-button:after, .dwa-share-dialog-footer-button:after, .dwa-share-dialog-list-button:after, .dwa-share-dialog-form-action:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity ease-in-out 0.15s;
}
.dwa-button:hover, .dwa-user-profile-footer-button:hover, .dwa-share-dialog-footer-button:hover, .dwa-share-dialog-list-button:hover, .dwa-share-dialog-form-action:hover, .dwa-button:focus, .dwa-user-profile-footer-button:focus, .dwa-share-dialog-footer-button:focus, .dwa-share-dialog-list-button:focus, .dwa-share-dialog-form-action:focus {
  outline: none;
}
.dwa-button:hover:after, .dwa-user-profile-footer-button:hover:after, .dwa-share-dialog-footer-button:hover:after, .dwa-share-dialog-list-button:hover:after, .dwa-share-dialog-form-action:hover:after, .dwa-button:focus:after, .dwa-user-profile-footer-button:focus:after, .dwa-share-dialog-footer-button:focus:after, .dwa-share-dialog-list-button:focus:after, .dwa-share-dialog-form-action:focus:after {
  opacity: 1;
}
.dwa-button.inline-button, .inline-button.dwa-user-profile-footer-button, .inline-button.dwa-share-dialog-footer-button, .dwa-share-dialog-list-button, .dwa-share-dialog-form-action {
  display: inline-block;
  width: auto;
  padding: 17px 8.5px;
}
.dwa-button.inner-shadow, .inner-shadow.dwa-user-profile-footer-button, .inner-shadow.dwa-share-dialog-footer-button, .dwa-share-dialog-list-button, .dwa-share-dialog-form-action {
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.dwa-button-text {
  font-family: "Open Sans", sans-serif;
  margin-left: 10px;
  font-weight: normal;
}

.dwa-link {
  color: rgba(35, 31, 32, 0.6);
  display: inline-block;
  text-decoration: underline;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}
.dwa-link:hover, .dwa-link:focus {
  outline: none;
  color: #231f20;
}

.dwa-btn {
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.dwa-btn.default {
  background-color: #00a6bf;
  color: #ffffff;
}

.dwa-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.85rem;
}
.dwa-breadcrumb-link {
  color: #00a6bf;
}
.dwa-breadcrumb-separator {
  color: #353535;
  margin: 0 6px;
}
.dwa-breadcrumb-text {
  color: #353535;
}

.dwa-card-thumb-header {
  display: flex;
  justify-content: flex-end;
  height: 16px;
  margin-bottom: 5px;
}
.dwa-card-thumb-item {
  flex: 0 0 20px;
  height: 100%;
  margin-left: 7px;
  text-align: center;
}
.dwa-card-thumb-item-button {
  color: #d9d9d9;
}
.dwa-card-thumb-item-button.active {
  color: #00a6bf;
}
.dwa-card-thumb-item-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.dwa-card-thumb-button-more {
  width: 100%;
  height: 140px;
  margin: 16px 0 15px;
  background-color: #00a6bf;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 800;
  transition: background 0.3s;
}
.dwa-card-thumb-button-more:hover {
  background-color: #004f5a;
}
.dwa-card-thumb-content {
  position: relative;
}
.dwa-card-thumb-link {
  color: #353535;
  display: block;
  cursor: pointer;
}
.dwa-card-thumb-icon {
  height: 100%;
  width: 100%;
}
.dwa-card-thumb-thumb {
  position: relative;
  overflow: hidden;
  padding-top: 75%;
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  background-size: cover;
  background-position: 50% 50%;
  margin: 0 0 15px;
}
.dwa-card-thumb-link:hover .dwa-card-thumb-thumb {
  opacity: 0.7;
}
.dwa-card-thumb-image {
  position: absolute;
  top: 50%;
  left: 0;
  max-width: 100%;
  min-width: 100%;
  transition: opacity 0.2s;
  transform: translateY(-50%);
}
.dwa-card-thumb-text {
  text-align: center;
  text-overflow: ellipsis;
  transition: color 0.2s;
  overflow: hidden;
}
.dwa-card-thumb-tag {
  position: absolute;
  font-size: 10px;
  background: red;
  padding: 1px 5px;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  top: -21px;
}
.dwa-card-thumb-menu {
  z-index: 2;
  justify-content: flex-end;
  overflow: hidden;
  pointer-events: none;
}
.dwa-card-thumb-menu-background {
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  background-color: #04707f;
  transition: opacity 0.5s;
}
.dwa-card-thumb-content:hover .dwa-card-thumb-menu-background {
  opacity: 0.5;
  transition-delay: 1.25s;
}
.dwa-card-thumb-menu-list {
  position: relative;
  z-index: 2;
  top: 1px;
  padding: 3px 0;
  background-color: #004f5a;
  pointer-events: all;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dwa-card-thumb-content:hover .dwa-card-thumb-menu-list {
  transform: translate3d(0, 0, 0);
  transition-delay: 1.25s;
}
.dwa-card-thumb-menu-item-button {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  color: #ffffff;
}
.dwa-card-thumb-menu-item-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.dwa-card-thumb-menu-item-icon {
  flex: 0 0 18px;
  height: 18px;
  margin-right: 6px;
  color: #00a6bf;
}

.dwa-card-grid-thumb {
  padding: 26px 35px;
  min-height: 20px;
}
.dwa-card-grid-thumb-wrapper {
  margin: 0 -15px;
}
.dwa-card-grid-thumb .dwa-card-thumb {
  width: 33%;
  box-sizing: border-box;
  display: inline-block;
  padding: 0 15px 30px;
  box-sizing: border-box;
}
@media only screen and (max-width: 425px) {
  .dwa-card-grid-thumb .dwa-card-thumb {
    width: 50%;
  }
}
.single .dwa-card-grid-thumb .dwa-card-thumb {
  width: 20%;
}
@media only screen and (max-width: 1023px) {
  .single .dwa-card-grid-thumb .dwa-card-thumb {
    width: 33%;
  }
}
@media only screen and (max-width: 425px) {
  .single .dwa-card-grid-thumb .dwa-card-thumb {
    width: 50%;
  }
}

.dwa-slide {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
  height: 100vh;
  max-height: 400px;
  margin: auto;
  overflow: hidden;
  border-radius: 4px;
}
.dwa-slide-pagination {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 50px;
  z-index: 2;
}
.dwa-slide-pagination-button {
  flex: 0 0 17px;
  height: 17px;
  margin: 5px 5px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dwa-slide-pagination-button::before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 100%;
  transform: scale(0);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dwa-slide-pagination-button:hover {
  transform: scale(0.95);
}
.dwa-slide-pagination-button.active {
  cursor: default;
  transform: scale(1);
}
.dwa-slide-pagination-button.active::before {
  transform: scale(1);
}
.dwa-slide-list {
  z-index: 1;
}
.dwa-slide-item {
  z-index: 1;
}
.dwa-slide-item.active {
  z-index: 2;
}
.dwa-slide-item-link {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 68px 50px;
  color: #ffffff;
  box-sizing: border-box;
}
.dwa-slide-item-background {
  background-position: 50%;
  background-size: cover;
  z-index: 1;
}
.dwa-slide-item-content {
  position: relative;
  width: 50%;
  opacity: 0;
  z-index: 2;
}
@media (max-width: 768px) {
  .dwa-slide-item-content {
    width: 100%;
  }
}
.dwa-slide-item-title {
  padding-right: 20%;
}
@media (max-width: 768px) {
  .dwa-slide-item-title {
    padding: 0;
  }
}
.dwa-slidethumbs {
  width: 100%;
  margin: auto;
  font-size: 0.85rem;
  overflow: hidden;
  box-sizing: border-box;
}
.dwa-slidethumbs-title-link {
  color: #231f20;
}
.dwa-slidethumbs-pagination {
  display: flex;
}
.dwa-slidethumbs-pagination-button {
  display: block;
  width: 24px;
  height: 24px;
  color: #000000;
  background-color: #d9d9d9;
  border-radius: 4px;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dwa-slidethumbs-pagination-button:hover {
  opacity: 0.8;
}
.dwa-slidethumbs-pagination-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: #d9d9d9;
  color: #000000;
}
.dwa-slidethumbs-pagination-button.prev {
  margin-right: 4px;
  transform: rotateZ(180deg);
}
.dwa-slidethumbs-pagination-icon-arrow {
  display: block;
  width: 100%;
  height: 54%;
}
.dwa-slidethumbs-list {
  display: flex;
  padding: 15px 0 30px;
  position: relative;
}
.dwa-slidethumbs-list-wrapper {
  overflow-x: scroll;
  margin-bottom: -17px;
  -webkit-overflow-scrolling: touch;
  padding-left: 35px;
  padding-right: 19px;
}
.dwa-slidethumbs-list-wrapper::-webkit-scrollbar {
  width: 17px;
}
.dwa-slidethumbs-item {
  flex: 0 0 calc(20% - 16px);
  max-width: calc(20% - 16px);
  padding-right: 16px;
}
@media only screen and (max-width: 425px) {
  .dwa-slidethumbs-item {
    flex: 0 0 calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}
.dwa-slidethumbs-item.one-third {
  flex: 0 0 calc(33.3333% - 16px);
  max-width: calc(33.3333% - 16px);
}
@media only screen and (max-width: 425px) {
  .dwa-slidethumbs-item.one-third {
    flex: 0 0 calc(100% - 16px);
    max-width: calc(100% - 16px);
  }
}
.dwa-slidethumbs-item:last-child {
  padding-right: 35px;
}

.dwa-publication-dialog-wrapper {
  margin-top: 54px;
}
.dwa-publication-dialog-wrapper .mat-dialog-container {
  background-color: transparent;
  overflow: hidden;
  border-radius: 0;
  padding: 0;
}
.dwa-publication-dialog-fullscreen {
  transition: none;
  height: 100vh !important;
  width: 100vw !important;
}
.dwa-publication-dialog-fullscreen .mat-dialog-container {
  background-color: transparent;
  overflow: hidden;
  border-radius: 0;
  padding: 0;
}
.dwa-publication-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}
.dwa-publication-dialog-content {
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: #231f20;
}
.dwa-publication-dialog-content.hide-controls {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}
.dwa-publication-dialog-content-iframe {
  transform-origin: top left;
  background-color: #ffffff;
  position: relative;
}
.hide-controls .dwa-publication-dialog-content-iframe {
  width: 100% !important;
  height: 100% !important;
}
.dwa-publication-dialog-content-video {
  width: 100%;
  height: 100%;
}
.dwa-publication-dialog-content-error {
  height: 100%;
  color: #ffffff;
  flex-direction: column;
}
.dwa-publication-dialog-content-error a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: bold;
}
.dwa-publication-dialog-loader {
  height: 100%;
  background-color: #231f20;
  color: #ffffff;
}
.dwa-publication-dialog-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row-reverse;
  background-color: #1a1a1a;
  transition: opacity ease-in-out 0.5s;
}
.hide-controls .dwa-publication-dialog-toolbar {
  display: none;
}
.history-opened .dwa-publication-dialog-toolbar {
  opacity: 1 !important;
}
.fullscreen-activated .dwa-publication-dialog-toolbar {
  width: 57px;
  height: 100%;
  flex-direction: column;
}
.fullscreen-activated .dwa-publication-dialog-toolbar.no-touch {
  opacity: 0;
}
.fullscreen-activated .dwa-publication-dialog-toolbar.no-touch:before {
  content: "";
  position: absolute;
  right: 57px;
  width: 50vw;
  height: 100%;
}
.fullscreen-activated .dwa-publication-dialog-toolbar.no-touch:hover {
  opacity: 1;
}
.dwa-publication-dialog-toolbar-item {
  position: relative;
}
.dwa-publication-dialog-toolbar-item-button {
  height: 100%;
}
.dwa-publication-dialog-toolbar-item-button.active, .active .dwa-publication-dialog-toolbar-item-button {
  opacity: 0.8;
}
.dwa-publication-dialog-toolbar-item-button:disabled {
  opacity: 0.2;
  pointer-events: none;
}
.fullscreen-activated .dwa-publication-dialog-toolbar-item-button {
  height: auto;
}
.dwa-publication-dialog-toolbar-item-icon {
  color: #ffffff;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 15px;
}
.fullscreen-activated .dwa-publication-dialog-toolbar-item-icon {
  width: 21px;
  height: 21px;
}
.close .dwa-publication-dialog-toolbar-item-icon {
  margin-left: 50px;
}
.history .dwa-publication-dialog-toolbar-item-icon {
  width: 19px;
  height: 19px;
}
.favorites .dwa-publication-dialog-toolbar-item-icon {
  width: 21px;
  height: 20px;
}
.toolbar .dwa-publication-dialog-toolbar-item-icon {
  width: 44px;
  margin: 15px 10px;
}
.fullscreen-activated .toolbar .dwa-publication-dialog-toolbar-item-icon {
  margin: 15px 7px;
}
.account-hub .dwa-publication-dialog-toolbar-item-icon {
  width: 27px;
  height: 22px;
}
.mail .dwa-publication-dialog-toolbar-item-icon {
  width: auto;
  font-size: 22px !important;
  transform: translateY(-2px);
}
.dwa-publication-dialog-toolbar-item-submenu {
  position: absolute;
  z-index: 1;
  top: 54px;
  right: 0;
  width: 300px;
  padding: 0 15px;
  font-size: 12px;
  text-align: left;
  color: #ffffff;
  background-color: #1a1a1a;
  border-top: 1px solid #4d4d4d;
}
.fullscreen-activated .dwa-publication-dialog-toolbar-item-submenu {
  top: 0;
  right: 57px;
  border-right: 1px solid #4d4d4d;
  border-top: none;
}
.dwa-publication-dialog-toolbar-item-submenu-item {
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333333;
}
.dwa-publication-dialog-toolbar-item-submenu-item:disabled {
  opacity: 0.2;
  pointer-events: none;
}
.dwa-publication-dialog-toolbar-item-submenu-item:last-child {
  border-bottom: none;
}
.dwa-publication-dialog-toolbar-item-submenu-image {
  display: block;
  max-width: 40px;
  margin-right: 10px;
}

.dwa-publications-list {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 35px 0;
}
.dwa-publications-item {
  flex: 1 1 350px;
  text-align: left;
  margin-bottom: 30px;
  position: relative;
}
.dwa-publications-item-wrapper {
  color: #353535;
  display: flex;
  height: 100%;
}
.dwa-publications-item-wrapper.link {
  cursor: pointer;
}
.dwa-publications-item-wrapper .fade {
  transition: opacity 0.2s;
}
.dwa-publications-item-wrapper:hover .fade {
  opacity: 0.7;
}
.dwa-publications-item-image {
  flex: 1 0 100%;
  max-width: 100px;
  height: 75px;
  background-size: cover;
  background-position: 50% 50%;
  transition: opacity 0.2s;
  outline: 1px solid #f7f7f7;
}
.dwa-publications-item-link.link:hover .dwa-publications-item-image {
  opacity: 0.7;
}
.dwa-publications-item-image dwa-content-progress-bar {
  position: relative;
  top: 71px;
  left: 0px;
}
.dwa-publications-item-content {
  flex: 0 1 100%;
  padding: 0 0 0 20px;
}
.dwa-publications-item-title {
  margin-top: -5px;
  font-weight: 800;
}
.dwa-publications-item-category {
  font-weight: 800;
}
.dwa-publications-item-category a:hover {
  text-decoration: underline;
}
.dwa-publications-item-category strong {
  color: #00a6bf;
}
.dwa-publications-item-date {
  display: flex;
}
.dwa-publications-item-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.dwa-session-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
.dwa-session-display-icon {
  width: 28px;
  height: 28px;
  margin-left: 20px;
  color: #00d800;
}
.dwa-session-display-text, .dwa-session-display-time {
  text-align: center;
}
.dwa-session-display-text strong, .dwa-session-display-time strong {
  display: block;
}
.dwa-session-display-text {
  padding: 0 20px 0;
  color: #353535;
}
.dwa-session-display-time {
  margin-left: 20px;
  padding: 15px 20px 18px;
  color: #ffffff;
  background-color: #e40001;
}
.dwa-session-display-time strong {
  font-size: 1.8rem;
  font-weight: 400;
}

.dwa-news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.dwa-news-item {
  width: 33.333333%;
  padding: 0 6px;
  margin: 0 0 28px;
  box-sizing: border-box;
}
.dwa-news-item-link {
  position: relative;
  overflow: hidden;
  display: block;
  color: #353535;
  border-bottom: 4px solid #eaeaea;
  cursor: pointer;
}
.dwa-news-item-image {
  display: block;
  height: 250px;
  transition: opacity 0.2s;
  background-size: cover;
  background-position: 50% 50%;
}
.dwa-news-item-link:hover .dwa-news-item-image {
  opacity: 0.7;
}
.dwa-news-item-content {
  padding: 28px;
  background-color: #ffffff;
}
.dwa-news-item-title {
  min-height: 50px;
  line-height: 1.6;
}
.dwa-news-item-text {
  height: 90px;
  overflow: hidden;
}

.dwa-share-dialog .mat-dialog-container {
  height: 80vh;
  padding: 0;
}
.dwa-share-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
.dwa-share-dialog-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.dwa-share-dialog-form {
  padding: 26px 35px;
  max-height: 282px;
  overflow-y: auto;
  text-align: right;
  position: relative;
}
.dwa-share-dialog-form-group {
  margin-bottom: 10px;
  padding-right: 45px;
}
.dwa-share-dialog-form-group.language-group {
  margin-bottom: -55px;
  text-align: left;
}
.dwa-share-dialog-form-field {
  width: 33.33%;
  padding-right: 10px;
  display: inline-block;
  box-sizing: border-box;
}
.dwa-share-dialog-form-field.firstname-field .dwa-field-input {
  padding-left: 92px;
}
.dwa-share-dialog-form-field.lastname-field .dwa-field-input {
  padding-left: 90px;
}
.dwa-share-dialog-form-field.email-field .dwa-field-input {
  padding-left: 61px;
}
.dwa-share-dialog-form-field.language-field .dwa-field-input {
  text-indent: 77px;
}
.dwa-share-dialog-form-text .textarea {
  height: 84px;
}
.dwa-share-dialog-form-action.add-button {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-bottom: 15px;
  width: 200px;
}
.dwa-share-dialog-form-action.add-button:before {
  content: "";
}
.dwa-share-dialog-form-action.remove-button {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  right: 0;
  width: 45px;
}
.dwa-share-dialog-form-action.remove-button:before {
  content: "";
}
.dwa-share-dialog-form .dwa-field-label {
  color: #000000;
  top: 14px;
}
.dwa-share-dialog-form .dwa-field-input {
  padding-left: 87px;
  background-color: #f7f7f7;
}
.dwa-share-dialog-form .dwa-field-input:focus {
  background-color: #d9d9d9;
}
.dwa-share-dialog-list {
  flex: 1;
  overflow-y: auto;
  padding: 26px 35px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.dwa-share-dialog-list-item {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(234, 234, 234, 0.5);
  display: flex;
}
.dwa-share-dialog-list-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.dwa-share-dialog-list-title {
  flex: 1;
}
.dwa-share-dialog-list-button {
  margin-left: 10px;
  padding: 7px 10px;
  color: #ffffff;
  background-color: #ccc;
}
.dwa-share-dialog-list-button.delete-button {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.dwa-share-dialog-list-button.delete-button:before {
  content: "";
}
.dwa-share-dialog-list-button.lock-button {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  background-color: #777;
}
.dwa-share-dialog-list-button.lock-button:before {
  content: "";
}
.dwa-share-dialog-list-button.unlock-button {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.dwa-share-dialog-list-button.unlock-button:before {
  content: "";
}
.dwa-share-dialog-footer {
  padding: 26px 35px;
  text-align: right;
}
.dwa-share-dialog-footer-button {
  height: 65px;
}
.dwa-share-dialog-footer-button .dwa-button-text {
  display: inline-block;
  vertical-align: middle;
  max-width: 160px;
}
.dwa-share-dialog-footer-button.upload-button {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-right: 15px;
  width: auto;
}
.dwa-share-dialog-footer-button.upload-button:before {
  content: "";
}
.dwa-share-dialog-footer-button.preview-button {
  display: inline-block;
  margin-right: 15px;
  width: 200px;
}
.dwa-share-dialog-footer-button.send-button {
  display: inline-block;
  width: 200px;
}

.preview {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.preview-message {
  background-color: #ffffff;
  position: absolute;
  width: 90%;
  height: calc(90% - 78px);
  top: 5%;
  left: 5%;
  border-radius: 10px;
  border: 1px solid #aaabab;
}
.preview-back {
  position: absolute;
  right: 5%;
  left: 5%;
  width: 90%;
  bottom: 26px;
}

.dwa-user-profile .mat-dialog-container {
  padding: 0;
}
.dwa-user-profile-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
.dwa-user-profile-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.dwa-user-profile-form {
  padding: 26px 35px;
  height: 60vh;
  overflow: auto;
}
.dwa-user-profile-form-group {
  margin-bottom: 20px;
}
.dwa-user-profile-form .dwa-field-label {
  color: #000000;
  position: static;
  margin-bottom: 5px;
  display: inline-block;
}
.dwa-user-profile-form .dwa-field-input {
  padding-left: 17px;
  background-color: #f7f7f7;
}
.dwa-user-profile-form .dwa-field-input:focus {
  background-color: #d9d9d9;
}
.dwa-user-profile-footer {
  padding: 26px 35px;
  border-top: 1px solid #eaeaea;
  text-align: right;
}
.dwa-user-profile-footer-button .dwa-button-text {
  display: inline-block;
  vertical-align: middle;
  max-width: 160px;
}
.dwa-user-profile-footer-button.send-button {
  display: inline-block;
  width: 200px;
}

.dwa-confirm-dialog-wrapper .mat-dialog-container {
  padding: 0;
}
.dwa-confirm-dialog-title {
  text-align: center;
  padding: 26px 35px;
}
.dwa-confirm-dialog-subtitle {
  display: block;
  text-align: center;
  padding: 26px 35px;
  padding-top: 0;
}
.dwa-confirm-dialog-list {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #efefef;
}
.dwa-confirm-dialog-item {
  border-bottom: 1px solid #efefef;
}
.dwa-confirm-dialog-item:last-child {
  border-bottom: none;
}
.dwa-confirm-dialog-button {
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}
.dwa-confirm-dialog-button.cancel {
  font-weight: bold;
}

.dwa-snackbar-error {
  background: red;
  color: white;
}
.dwa-snackbar-error .mat-button {
  color: white;
}

mat-progress-bar .mat-progress-bar-buffer {
  background: #76ff03;
}
mat-progress-bar .mat-progress-bar-fill::after {
  background: white;
}

.dwa-login {
  background-image: url("/assets/images/pages/login/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  visibility: hidden;
}
.dwa-login.visible {
  visibility: visible;
}
.dwa-login-container {
  width: 350px;
  text-align: center;
}
.dwa-login-subtitle {
  display: block;
  margin-top: 20px;
  padding: 0 30px;
  font-size: 13px;
}
.dwa-login-form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.dwa-login-field {
  width: 350px;
}
.dwa-login-option {
  height: 110px;
}
.dwa-login-option-item {
  position: absolute;
}
.dwa-login-icon-username:before {
  content: "";
}
.dwa-login-icon-password:before {
  content: "";
}
.dwa-login-bottom {
  margin-top: 15px;
}

.dwa-dashboard-publications {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -5px;
}
.dwa-dashboard-publication {
  flex: 1;
  max-height: 425px;
  overflow: hidden;
  margin: 15px 5px;
  min-width: 350px;
}
.dwa-dashboard-publication .dwa-publications-list {
  overflow-y: scroll;
  margin-right: -17px;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 109px);
}
.dwa-dashboard-publication .dwa-publications-list::-webkit-scrollbar {
  width: 17px;
}
@media only screen and (max-width: 1023px) {
  .dwa-kiosks {
    flex-wrap: wrap;
  }
}
.dwa-kiosks-list {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 1023px) {
  .dwa-kiosks-list {
    flex: 1 1 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .dwa-kiosks-item {
    width: 49%;
  }
}
.dwa-kiosks-item.single {
  width: 100%;
}

.dwa-article-image {
  max-width: 100%;
  margin-bottom: 45px;
  border-radius: 4px;
}
.dwa-article-title {
  margin-bottom: 15px;
}
.dwa-article-subtitle {
  margin-bottom: 20px;
}
.dwa-article-entry p {
  margin-bottom: 20px;
}
.dwa-article-footer {
  display: flex;
}
.dwa-article-share {
  display: flex;
  align-items: center;
}
.dwa-article-share-title {
  margin-right: 4px;
  font-weight: 800;
}
.dwa-article-share-link {
  margin-left: 8px;
  border: 1px solid #aaabab;
  color: #aaabab;
}
.dwa-article-share-icon {
  width: 16px;
  height: 16px;
  margin: 5px;
}

.dwa-help-search {
  padding: 25px 50px 50px;
  background-color: #00a6bf;
  border-radius: 4px;
}
.dwa-help-search-title {
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 300;
  text-align: center;
}
.dwa-help-search-field {
  display: flex;
  height: 70px;
  padding: 0 25px;
  background-color: #ffffff;
  border-radius: 4px;
}
.dwa-help-search-icon {
  flex: 0 0 27px;
  height: 100%;
  color: #aaabab;
}
.dwa-help-search-input {
  flex: 1 1 100%;
  padding: 0 30px;
  height: 100%;
}
.dwa-help-content {
  display: flex;
  margin-top: 34px;
}
.dwa-help-faqs {
  flex: 1 1 60%;
  margin-right: 55px;
}
.dwa-help-faqs .dwa-faq-posts {
  margin-bottom: 55px;
}
.dwa-help-sidebar {
  flex: 1 1 40%;
}
.dwa-help-sidebar-title {
  margin-bottom: 38px;
  padding: 7px 0 11px;
  color: #353535;
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 1px solid #00a6bf;
}
.dwa-help-sidebar-item-link {
  display: flex;
  padding: 0 0 20px;
  color: #353535;
}
.dwa-help-sidebar-item-icon {
  flex: 0 0 14px;
  height: 14px;
  margin: 3px 10px 0 0;
}
.dwa-faq-posts-title {
  padding: 10px 28px;
  margin-bottom: 28px;
  color: #ffffff;
  background-color: #00a6bf;
  border-radius: 4px;
}
.dwa-faq-posts-item {
  border-bottom: 1px solid #eaeaea;
}
.dwa-faq-posts-item-link {
  display: flex;
  padding: 14px 5px;
  color: #353535;
}
.dwa-faq-posts-item-icon {
  flex: 0 0 14px;
  height: 14px;
  margin: 3px 14px 0 0;
}
.dwa-account-hub {
  width: 100vw;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  max-width: none;
}
.sidebar-opened .dwa-account-hub {
  left: 0;
}
.dwa-account-hub-content {
  position: absolute;
  z-index: 1;
  top: -20px;
  left: 0;
  width: 100%;
  height: calc(100vh - 54px);
}
@media only screen and (max-width: 1439px) {
  .dwa-account-hub-content {
    height: calc(100vh - 108px);
  }
}
.dwa-account-hub-iframe {
  width: 100%;
  height: 100%;
  display: block;
}

.dwa-crm {
  width: 100vw;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  max-width: none;
}
.sidebar-opened .dwa-crm {
  left: 0;
}
.dwa-crm-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 185px);
}
@media only screen and (max-width: 1439px) {
  .dwa-crm-login {
    min-height: calc(100vh - 239px);
  }
}
.dwa-crm-login-form {
  width: 400px;
}
.dwa-crm-content {
  position: absolute;
  z-index: 1;
  top: -20px;
  left: 0;
  width: 100%;
  height: calc(100vh - 54px);
}
@media only screen and (max-width: 1439px) {
  .dwa-crm-content {
    height: calc(100vh - 108px);
  }
}
.dwa-crm-iframe {
  width: 100%;
  height: 100%;
  display: block;
}