.dwa-btn {
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;

  &.default {
    background-color: #00a6bf;
    color: #ffffff;
  }
}
