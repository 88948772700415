// CARD THUMB

.dwa-card-thumb
  &-header
    display: flex
    justify-content: flex-end
    height: $card-thumb-header
    margin-bottom: 5px


  &-item
    flex: 0 0 20px
    height: 100%
    margin-left: 7px
    text-align: center


    &-button
      @extend .dwa-hover-opacity

      color: $color-gray-5

      &.active
        color: $color-green-1

      &:disabled
        opacity: 0.5
        pointer-events: none


  &-button-more
    @extend .dwa-flex-center

    width: 100%
    height: 140px
    margin: $card-thumb-header 0 15px
    background-color: $color-green-1
    color: $color-white
    font-size: 2.5rem
    font-weight: $font-bold
    transition: background 0.3s

    &:hover
      background-color: $color-green-5


  &-content
    @extend .dwa-flex-column

    position: relative


  &-link
    @extend .dwa-hover-opacity
    @extend .dwa-flex-column

    color: $color-gray-4
    display: block
    cursor: pointer


  &-icon
    height: 100%
    width: 100%


  &-thumb
    position: relative
    overflow: hidden
    padding-top: 75%
    border: 1px solid $color-gray-1
    background-color: $color-gray-1
    background-size: cover
    background-position: 50% 50%
    margin: 0 0 15px

    .dwa-card-thumb-link:hover &
      opacity: 0.7


  &-image
    position: absolute
    top: 50%
    left: 0
    max-width: 100%
    min-width: 100%
    transition: opacity 0.2s
    transform: translateY(-50%)


  &-text
    @extend .dwa-text-small

    text-align: center
    text-overflow: ellipsis
    transition: color 0.2s
    overflow: hidden


  &-tag
    position: absolute
    font-size: 10px
    background: red
    padding: 1px 5px
    color: white
    border-radius: 5px
    text-transform: uppercase
    font-weight: bold
    top: -21px


  &-menu
    @extend .dwa-absolute-full
    @extend .dwa-flex-column

    z-index: 2
    justify-content: flex-end
    overflow: hidden
    pointer-events: none


    &-background
      @extend .dwa-absolute-full

      z-index: 1
      opacity: 0
      pointer-events: none
      background-color: $color-green-2
      transition: opacity 0.5s

      .dwa-card-thumb-content:hover &
        opacity: 0.5
        transition-delay: $card-thumb-menu-delay


    &-list
      @extend .dwa-flex-column

      position: relative
      z-index: 2
      top: 1px
      padding: 3px 0
      background-color: $color-green-5
      pointer-events: all
      transform: translate3d(0, 100%, 0)
      transition: transform $layout-speed $layout-easing

      .dwa-card-thumb-content:hover &
        transform: translate3d(0, 0, 0)
        transition-delay: $card-thumb-menu-delay


    &-item
      &-button
        @extend .dwa-text-tiny
        @extend .dwa-hover-opacity

        display: flex
        padding: 10px 15px
        align-items: center
        color: $color-white

        &:disabled
          opacity: 0.5
          pointer-events: none


      &-icon
        flex: 0 0 $card-thumb-menu-item-icon
        height: $card-thumb-menu-item-icon
        margin-right: 6px
        color: $color-green-1


// Layout

.dwa-card-grid
  &-thumb
    padding: $card-style-header-padding
    min-height: 20px

    &-wrapper
      margin: $card-thumb-margin

    .dwa-card-thumb
      width: 33%
      box-sizing: border-box
      display: inline-block
      padding: $card-thumb-padding
      box-sizing: border-box

      +query-before(phablet, true)
        width: 50%

      .single &
        width: 20%

        +query-before(tablet2)
          width: 33%

        +query-before(phablet, true)
          width: 50%
