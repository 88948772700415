// TERMS

.dwa-terms
  .mat-dialog-container
    @extend .dwa-card-style

    padding: 0


  &-backdrop
    background-color: rgba($color-black, 0.7)


  &-component
    width: 75vw
    max-width: 1000px
    text-align: center
    display: block
    overflow: hidden


  &-title
    font-size: 20px
    margin: 20px
    color: $color-green-1

  &-content
    height: 60vh
    overflow-y: scroll
    text-align: left
    padding: 0 20px

  &-button
    @extend .dwa-hover-opacity

    padding: 20px
    margin: 20px
    font-size: 20px
    color: $color-white
    background-color: $color-green-1

    &:disabled
      opacity: 0.5
      pointer-events: none
