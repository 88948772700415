.dwa-version
  position: fixed
  pointer-events: none
  right: 0
  bottom: 0
  padding: 5px
  font-size: 10px

  +query-before(tablet2, true)
    position: relative
    text-align: right
    margin-top: -25px
