// RESET

a
  text-decoration: none

button,
input,
textarea
  padding: 0
  background: 0
  border: 0
  outline: 0
  box-sizing: border-box

button
  cursor: pointer

input,
textarea
  overflow: hidden
