// BREADCRUMB
//

.dwa-breadcrumb
  display: flex
  flex-wrap: wrap
  font-size: 0.85rem


  &-link
    color: $color-green-1

    @extend .dwa-hover-color-black


  &-separator
    color: $color-gray-4
    margin: 0 6px


  &-text
    color: $color-gray-4
