// CONTENT

// Main

.dwa-content
  background: $color-gray-3
  padding: ($topnav-height + $content-padding-top) 0
  min-height: 100vh
  box-sizing: border-box
  position: relative
  transition: margin-left $layout-speed $layout-easing

  +query-before(widescreen)
    padding-top: ($topnav-height * 2) + $content-padding-top

  .sidebar-opened &
    margin-left: $sidebar-width

    +query-before(tablet2)
      margin-left: 0


  &-main
    @extend .dwa-flex-column

    left: 0
    width: calc(100vw - #{$sidebar-width + 50px})
    margin: auto
    max-width: $content-main-max-width

    +query-before(tablet2)
      width: $content-main-max-mobile

    .sidebar-opened &
      left: $sidebar-width

      +query-before(tablet2)
        left: 0


  &-header
    margin-bottom: $content-header-margin-bottom


  &-title
    @extend .dwa-title
    @extend .truncate-text

    display: flex
    align-items: center
    font-weight: $font-bold

    &-wrapper
      min-height: 50px
      display: flex
      align-items: flex-end
      justify-content: space-between
      margin-bottom: $content-header-margin-bottom

    &-icon
      margin: 10px 20px 10px 0

    &-item
      align-items: flex-end
      display: flex

