// SESSION DISPLAY
//

$session-display-icon: 28px
$session-display-margin: 28px
$session-display-items-margin: 20px
$session-display-time-font-size: 1.8rem


//

.dwa-session-display
  display: flex
  align-items: center
  justify-content: space-between
  background-color: $color-white

  &-icon
    width: $session-display-icon
    height: $session-display-icon
    margin-left: $session-display-items-margin
    color: $color-lime


  &-text,
  &-time
    @extend .dwa-text-big

    text-align: center

    strong
      display: block

  &-text
    padding: 0 $session-display-items-margin 0
    color: $color-gray-4

  &-time
    margin-left: $session-display-items-margin
    padding: 15px $session-display-items-margin 18px
    color: $color-white
    background-color: $color-red

    strong
      font-size: $session-display-time-font-size
      font-weight: $font-regular
