.dwa-account-hub
  width: 100vw
  padding: 0 20px
  box-sizing: border-box
  position: relative
  max-width: none

  .sidebar-opened &
    left: 0


  &-content
    position: absolute
    z-index: 1
    top: -20px
    left: 0
    width: 100%
    height: calc(100vh - #{$topnav-height})

    +query-before(widescreen)
      height: calc(100vh - #{$topnav-height * 2})


  &-iframe
    width: 100%
    height: 100%
    display: block
