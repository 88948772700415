// ACCOUNT-HUB

.dwa-user-profile
  .mat-dialog-container
    @extend .dwa-card-style

    padding: 0

  &-backdrop
    background-color: rgba($color-black, 0.7)

  &-wrapper
    display: flex
    flex-direction: column
    position: relative


  &-header
    @extend .dwa-card-style-header

  &-title
    @extend .dwa-card-style-title


  &-form
    padding: $card-style-header-padding
    height: 60vh
    overflow: auto

    &-group
      margin-bottom: 20px

    .dwa-field-label
      color: $color-black
      position: static
      margin-bottom: 5px
      display: inline-block

    .dwa-field-input
      padding-left: $form-input-padding
      background-color: $color-gray-1

      &:focus
        background-color: $color-gray-5



  &-footer
    padding: $card-style-header-padding
    border-top: 1px solid $color-border-topnav
    text-align: right

    &-button
      @extend .dwa-button

      .dwa-button-text
        display: inline-block
        vertical-align: middle
        max-width: 160px

      &.send-button
        display: inline-block
        width: 200px
