// LAYOUT SHARED

.dwa-card-style
  background: $color-white
  border: 1px solid $color-border
  border-radius: $border-radius

  &-header
    align-items: center
    display: flex
    justify-content: space-between
    padding: $card-style-header-padding
    border-bottom: 1px solid $color-border-topnav


  &-title
    @extend .dwa-subtitle


.dwa-margin-bottom
  margin-bottom: 30px
