// NEWS LIST
// Vars

$news-item-text: 90px
$news-item-height: 250px
$news-item-margin: 28px

// Selectors

.dwa-news-list
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  align-items: stretch


.dwa-news-item
  width: 33.333333%
  padding: 0 6px
  margin: 0 0 $news-item-margin
  box-sizing: border-box


  &-link
    position: relative
    overflow: hidden
    display: block
    color: $color-gray-4
    border-bottom: 4px solid $color-border-topnav
    cursor: pointer


  &-image
    display: block
    height: $news-item-height
    transition: opacity 0.2s
    background-size: cover
    background-position: 50% 50%

    .dwa-news-item-link:hover &
      opacity: 0.7


  &-content
    padding: $news-item-margin
    background-color: $color-white


  &-title
    @extend .dwa-text-small

    min-height: 50px
    line-height: 1.6


  &-text
    @extend .dwa-text

    height: $news-item-text
    overflow: hidden
