@mixin gradient-fade($color, $break, $initial: 1) {
  background: linear-gradient(
    to bottom,
    rgba($color, $initial) 0%,
    rgba($color, $initial) $break,
    rgba($color, 0) 100%
  );
}


@mixin reset-button($padding: 0) {
  border: 0;
  outline: 0;
  padding: $padding;
  background-color: transparent;
  cursor: pointer;
}


@mixin query-between($start, $end, $include: false) {
  $s: map-get($breaks, $start);
  $e: map-get($breaks, $end);

  @if ($include == false) {
    $e: $e - 1;
  }

  @media only screen and (min-width: $s) and (max-width: $e) {
    @content;
  }
}

@mixin query-before($break, $include: false) {
  $n: map-get($breaks, $break);

  @if ($include == false) {
    $n: $n - 1;
  }

  @media only screen and (max-width: $n) {
    @content;
  }
}

@mixin query-after($break, $include: true) {
  $n: map-get($breaks, $break);

  @if ($include == false) {
    $n: $n + 1;
  }

  @media only screen and (min-width: $n) {
    @content;
  }
}

@mixin hidden-scroll($axys: y) {
  $offset: 17px;
  $direction: if($axys == y, right, bottom);

  overflow-#{$axys}: scroll;
  margin-#{$direction}: -$offset;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: $offset;
  }
}
