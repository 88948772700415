// ACCOUNT-HUB

.dwa-share-dialog
  .mat-dialog-container
    @extend .dwa-card-style

    height: 80vh
    padding: 0

  &-backdrop
    background-color: rgba($color-black, 0.7)

  &-wrapper
    height: 100%
    display: flex
    flex-direction: column
    position: relative


  &-header
    @extend .dwa-card-style-header

  &-title
    @extend .dwa-card-style-title


  &-form
    padding: $card-style-header-padding
    max-height: 282px
    overflow-y: auto
    text-align: right
    position: relative


    &-group
      margin-bottom: 10px
      padding-right: 45px

      &.language-group
        margin-bottom: -55px
        text-align: left

    &-field
      width: 33.33%
      padding-right: 10px
      display: inline-block
      box-sizing: border-box

      &.firstname-field
        .dwa-field-input
          padding-left: 92px

      &.lastname-field
        .dwa-field-input
          padding-left: 90px

      &.email-field
        .dwa-field-input
          padding-left: 61px

      &.language-field
        .dwa-field-input
          text-indent: 77px


    &-text
      .textarea
        height: 84px

    &-action
      @extend .dwa-button
      @extend .inline-button
      @extend .inner-shadow

      &.add-button
        +icon-type(false, 900)
        +icon-image('plus')
        margin-bottom: 15px
        width: 200px

      &.remove-button
        +icon-type(false, 900)
        +icon-image('trash')
        position: absolute
        right: 0
        width: 45px


    .dwa-field-label
      color: $color-black
      top: $form-input-padding - 3px

    .dwa-field-input
      padding-left: $form-input-padding + 70px
      background-color: $color-gray-1

      &:focus
        background-color: $color-gray-5



  &-list
    flex: 1
    overflow-y: auto
    padding: $card-style-header-padding
    border-top: 1px solid $color-border-topnav
    border-bottom: 1px solid $color-border-topnav

    &-item
      margin-bottom: 7px
      padding-bottom: 7px
      border-bottom: 1px solid rgba($color-border-topnav, 0.5)
      display: flex

      &:last-child
        margin-bottom: 0
        border-bottom: none


    &-title
      flex: 1


    &-button
      @extend .dwa-button
      @extend .inline-button
      @extend .inner-shadow
      margin-left: 10px
      padding: 7px 10px
      color: $color-white
      background-color: #ccc

      &.delete-button
        +icon-type(false, 900)
        +icon-image('trash')

      &.lock-button
        +icon-type(false, 900)
        +icon-image('lock')
        background-color: #777

      &.unlock-button
        +icon-type(false, 900)
        +icon-image('unlock')


  &-footer
    padding: $card-style-header-padding
    text-align: right

    &-button
      @extend .dwa-button
      height: 65px

      .dwa-button-text
        display: inline-block
        vertical-align: middle
        max-width: 160px

      &.upload-button
        +icon-type(false, 900)
        +icon-image('cloud-upload-alt')
        margin-right: 15px
        width: auto

      &.preview-button
        display: inline-block
        margin-right: 15px
        width: 200px

      &.send-button
        display: inline-block
        width: 200px


.preview
  background-color: rgba($color-white, 0.5)
  position: absolute
  z-index: 2
  width: 100%
  height: 100%
  top: 0
  left: 0

  &-message
    background-color: $color-white
    position: absolute
    width: 90%
    height: calc(90% - 78px)
    top: 5%
    left: 5%
    border-radius: 10px
    border: 1px solid $color-gray-6

  &-back
    position: absolute
    right: 5%
    left: 5%
    width: 90%
    bottom: 26px
