// PUBLICATIONS LIST
//

.dwa-publications
  &-list
    display: flex
    flex-wrap: wrap
    padding: 30px 35px 0


  &-item
    flex: 1 1 350px
    text-align: left
    margin-bottom: 30px
    position: relative


    &-wrapper
      color: $color-gray-4
      display: flex
      height: 100%

      &.link
        cursor: pointer

      .fade
        transition: opacity 0.2s

      &:hover
        .fade
          opacity: 0.7


    &-image
      flex: 1 0 100%
      max-width: 100px
      height: 75px
      background-size: cover
      background-position: 50% 50%
      transition: opacity 0.2s
      outline: 1px solid $color-gray-1

      .dwa-publications-item-link.link:hover &
        opacity: 0.7

      dwa-content-progress-bar 
        position: relative
        // progress bar is 4px so 75-4 = 71
        top: 71px
        left: 0px


    &-content
      flex: 0 1 100%
      padding: 0 0 0 20px



    &-title
      @extend .dwa-text

      margin-top: -5px
      font-weight: $font-bold


    &-category
      @extend .dwa-text

      font-weight: $font-bold

      a:hover
        text-decoration: underline


      strong
        color: $color-green-1


    &-text
      @extend .dwa-text


    &-date
      @extend .dwa-text-small

      display: flex


    &-icon
      width: 20px
      height: 20px
      margin-right: 5px
