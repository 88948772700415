// KIOSKS

.dwa-kiosks
  +query-before('tablet2')
    flex-wrap: wrap

  &-list
    @extend .dwa-flex-column

    flex-wrap: wrap
    flex-direction: row
    justify-content: space-between


    +query-before('tablet2')
      flex: 1 1 100%

  &-item
    @extend .dwa-margin-bottom

    +query-after('tablet2', true)
      width: 49%

    &.single
      width: 100%

