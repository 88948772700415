.dwa-crm
  width: 100vw
  padding: 0 20px
  box-sizing: border-box
  position: relative
  max-width: none

  .sidebar-opened &
    left: 0


  &-login
    display: flex
    align-items: center
    justify-content: center
    min-height: calc(100vh - 185px)

    +query-before(widescreen)
      min-height: calc(100vh - 239px)

    &-form
      width: 400px



  &-content
    position: absolute
    z-index: 1
    top: -20px
    left: 0
    width: 100%
    height: calc(100vh - #{$topnav-height})

    +query-before(widescreen)
      height: calc(100vh - #{$topnav-height * 2})


  &-iframe
    width: 100%
    height: 100%
    display: block
