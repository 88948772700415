// TOPNAV
// Main

.dwa-topnav
  position: fixed
  z-index: $topnav-zindex
  top: 0
  left: 0
  right: 0
  height: $topnav-height
  display: flex
  flex-direction: row-reverse
  align-items: center
  justify-content: space-between
  background-color: $color-topnav-background
  border-bottom: 1px solid $color-border-topnav
  transition: margin-left $layout-speed $layout-easing

  +query-before(widescreen)
    height: $topnav-height * 2
    display: block

  .sidebar-opened &
    margin-left: $sidebar-width



  &-toggle
    @extend .dwa-hover-opacity

    +reset-button(10px 20px)
    height: $topnav-height
    color: $color-green-1
    background-color: $color-topnav-background

    &-icon
      +icon-type(false, 900)
      +icon-image('bars')
      font-size: $topnav-menu-icon-size


  &-start
    display: flex
    flex: 1
    height: $topnav-height
    max-width: 600px
    align-items: center

    +query-before(widescreen)
      max-width: none


  &-search
    border: none
    border-radius: 16px
    background-color: $color-gray-1
    font-size: 12px
    padding: 10px 20px
    height: 28px
    margin-right: 20px
    transition: background-color $layout-speed $layout-easing

    &:active,
    &:focus-within
      background-color: darken($color-gray-1, 10%)
      outline: none


  &-sections
    display: flex
    flex: 1
    height: $topnav-height
    border-right: 1px solid $color-border-topnav
    background-color: $color-topnav-background

    &-link
      @extend .dwa-hover-opacity-inverse

      display: flex
      flex-grow: 1
      justify-content: center
      align-items: center
      padding: 0 15px
      font-size: 12px
      border-left: 1px solid $color-border
      color: $color-black
      text-align: center

      &.active
        opacity: 1
        pointer-events: none


  &-menu
    display: flex
    height: $topnav-height

    .mat-slide-toggle
        height: 100%

    &-item
      flex: 1
      text-align: center
      border-right: 1px solid $color-border
      background-color: $color-topnav-background

      +query-before(widescreen)
        border-bottom: 1px solid $color-border

      &:first-child
        border-left: 1px solid $color-border

      &.user
        border-right: none
        border-left: 1px solid $color-border
        position: relative
        z-index: 2

        // &.opened
        //   background-color: $color-white

        +query-before(widescreen)
          margin-left: auto

      &.custom-button
        background-color: $color-topnav-buttons

        +query-before(widescreen)
          flex-grow: 0.75

      &-link
        @extend .dwa-hover-opacity

        position: relative
        display: flex
        align-items: center
        justify-content: center
        padding: 0 10px
        margin: auto
        height: 100%
        color: $color-black
        cursor: pointer

        +query-after(tablet1, true)
          padding: 0 20px

        .rotate
          transition: 0.4s transform ease-in-out

        .green
          color: $color-green-1
          text-align: center


        &:hover
          .rotate
            transform: rotate(90deg)

      &-image
        max-width: $topnav-menu-icon-size * 1.5

        +query-before(tablet1)
          max-width: $topnav-menu-icon-size


      &-icon
        width: $topnav-menu-icon-size
        height: $topnav-menu-icon-size

        &.icon-mail
          +icon-type(false, 900)
          +icon-image('envelope')
          font-size: 21px
          margin-top: 2px

        &.icon-shared
          width: $topnav-menu-icon-size * 1.3
          height: $topnav-menu-icon-size * 1.3


      &-notification
        @extend .dwa-flex-center

        position: absolute
        top: 35%
        right: 20%
        width: $topnav-menu-notification-size
        height: $topnav-menu-notification-size
        margin: -9px 0 0 -9px
        color: $color-white
        font-size: 0.7rem
        font-weight: $font-bold
        text-align: center
        background: $color-green-1
        border-radius: 100%


      &-reload
        +icon-type(false, 900)
        +icon-image('sync')

        font-size: $topnav-menu-icon-size

      &-search
        +icon-type(false, 900)
        +icon-image('search')

        font-size: $topnav-menu-icon-size

      &-lms
        +icon-type(false, 900)
        +icon-image('graduation-cap')

        font-size: $topnav-menu-icon-size


    &-link
      &-content
        @extend .dwa-flex-column

        padding: 0 14px
        text-align: left

        +query-before(tablet1)
          display: none


      &-title
        font-size: 0.85rem

      &-subtitle
        font-weight: $font-bold

      &-email
        min-width: 170px
        font-size: 1.1em


    &-tooltip
      position: absolute
      top: 65px
      padding: 5px 10px
      color: $color-white
      background-color: rgba($color-black, 0.5)

      &:after
        content: ''
        position: absolute
        top: -5px
        left: 30px
        margin-left: -5px
        border-bottom: 5px solid rgba($color-black, 0.5)
        border-right: 5px solid transparent
        border-left: 5px solid transparent


    &-avatar
      width: $topnav-menu-avatar-size
      height: $topnav-menu-avatar-size
      background-color: $color-green-1
      background-size: cover
      background-position: 50% 50%
      border-radius: 100%
      overflow: hidden


    &-submenu
      position: absolute
      top: 57px
      right: 10px
      width: 320px
      color: $color-black
      background-color: $color-white
      border: 1px solid $color-gray-1
      border-radius: 6px

      &:after
        content: ''
        position: absolute
        top: -5px
        right: 30px
        width: 10px
        height: 10px
        background-color: $color-white
        border-right: 1px solid $color-gray-1
        border-top: 1px solid $color-gray-1
        transform: rotate(-45deg)


      &-title
        padding: 15px 20px
        font-size: 1.3em
        display: flex
        align-items: center

        &-icon
          margin-left: 5px
          +icon-type(false, 900)
          +icon-image('cog')

        &-button
          margin-left: auto
          text-decoration: underline

      &-section
        padding: 15px 20px
        border-top: 1px solid $color-gray-1
        font-size: 0.95em

        .section-item
          display: block
          margin-top: 2px

        .section-link
          &:hover
            text-decoration: underline


  &-backdrop
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1
    background: rgba($color-black, 0.05)
