// DASHBOARD

.dwa-dashboard
  &-publications
    display: flex
    flex-wrap: wrap
    margin: -15px -5px

  &-publication
    flex: 1
    max-height: $publication-list-height
    overflow: hidden
    margin: 15px 5px
    min-width: 350px

    .dwa-publications-list
      +hidden-scroll(y)
      max-height: calc(100% - #{$publication-title-height})


  .dwa-slidethumbs,
  .dwa-slide
    @extend .dwa-margin-bottom
