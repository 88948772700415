// Layout

$border-radius: 4px;

$layout-speed: .4s;
$layout-easing: cubic-bezier(.25, .8, .25, 1);

$sidebar-width: 200px;
$sidebar-icon-size: 30px;
$sidebar-item-size: 14px;
$sidebar-kiosk-icon-width: 25px;

$topnav-height: 54px;
$topnav-menu-icon-size: 20px;
$topnav-menu-notification-size: 18px;
$topnav-menu-avatar-size: 44px;

$content-padding-left: 74px;
$content-padding-top: 20px;
$content-main-max-width: 1320px;
$content-main-max-mobile: 95vw;
$content-header-margin-bottom: 20px;

$publication-list-height: 425px;
$publication-title-height: 109px;
$publication-toolbar-width: 57px;

$slidethumbs-pagination-button: 24px;
$slidethumbs-padding-left: 35px;
$slidethumbs-space-between: 16px;

$card-style-header-padding: 26px 35px;
$card-thumb-header: 16px;
$card-thumb-menu-item-icon: 18px;
$card-thumb-menu-delay: 1.25s;
$card-thumb-max-width: 180px;
$card-thumb-margin: 0 -15px;
$card-thumb-padding: 0 15px 30px;

$confirm-dialog-padding: 26px 35px;


// ZIndex

$topnav-zindex: 10;
$sidebar-zindex: 20;


// Typography

$font-main: 'Open Sans', sans-serif;
$font-icons: 'Font Awesome 5';
$font-brands: 'Font Awesome 5 Brands';
$font-size: 14px;
$font-bold: 800;
$font-regular: 400;
$font-light: 300;

$font-sidebarlink-size: 14px;
$font-arrow-down-size: 14px;


// Forms

$form-input-size: 14px;
$form-input-padding: 17px;
$form-input-margin: 5px;
$form-input-radius: 5px;


// Colors

$color-white: #ffffff;
$color-black: #000000;
$color-red: #e40001;
$color-green-1: #00a6bf;
$color-green-2: #04707f;
$color-green-3: #01626f;
$color-green-4: #0fa6bf;
$color-green-5: #004f5a;
$color-lime: #00d800;
$color-gray-1: #f7f7f7;
$color-gray-2: #231f20;
$color-gray-3: #f4f4f6;
$color-gray-4: #353535;
$color-gray-5: #d9d9d9;
$color-gray-6: #aaabab;

$color-topnav-background: #e5e6e8;
$color-topnav-buttons: #e5e6e8;

$color-border: #efefef;
$color-border-topnav: #eaeaea;


// Responsive breakpoints

$breaks: (
  zero: 0,
  mobile: 320px,
  phablet: 425px,
  tablet1: 768px,
  tablet2: 1024px,
  macbook: 1280px,
  notebook: 1360px,
  widescreen: 1440px
);
