.dwa-confirm-dialog
  &-wrapper
    .mat-dialog-container
      padding: 0


  &-title
    @extend .dwa-subtitle

    text-align: center
    padding: $confirm-dialog-padding


  &-subtitle
    @extend .dwa-text-small

    display: block
    text-align: center
    padding: $confirm-dialog-padding
    padding-top: 0


  &-list
    display: flex
    flex-direction: column
    border-top: 1px solid $color-border


  &-item
    border-bottom: 1px solid $color-border

    &:last-child
      border-bottom: none


  &-button
    @extend .dwa-text
    @extend .dwa-hover-opacity

    padding: 10px 20px
    text-align: center
    width: 100%

    &.cancel
      font-weight: bold
