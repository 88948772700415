// SIDEBAR
// Container

.dwa-sidebar
  @extend .dwa-flex-column

  position: fixed
  top: 0
  left: -$sidebar-width
  z-index: $sidebar-zindex
  width: $sidebar-width
  height: 100%
  background: $color-green-2
  transition: left $layout-speed $layout-easing

  .sidebar-opened &
    left: 0


  &-logo
    @extend .dwa-hover-opacity

    position: fixed
    z-index: 1
    width: $sidebar-width
    height: $topnav-height
    background: $color-green-1
    display: flex
    align-items: center
    justify-content: center

    &-image
      max-height: 40px


  &-list
    margin-top: $topnav-height


  &-item
    @extend .dwa-flex-column

    overflow: hidden
    border-top: 1px solid rgba($color-black, 0.1)
    transition: background $layout-speed $layout-easing


    &-link
      @extend .dwa-hover-color-white
      @extend .dwa-flex-column

      position: relative
      z-index: 1
      padding: 20px
      color: $color-green-1
      font-size: $font-sidebarlink-size
      text-align: center

      .active &
        color: $color-white


      &-background
        background-color: $color-green-2
        position: absolute
        z-index: 1
        top: 0
        left: 0
        width: 100%
        height: 100%

        &:after
          content: ''
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          background-color: rgba($color-black, 0.25)
          opacity: 0
          transition: opacity $layout-speed $layout-easing

          .active.change-on-active &
            opacity: 1


      &-arrow
        width: 10px
        height: 10px
        position: absolute
        border: 1px solid $color-green-1
        bottom: -9px
        left: 50%
        background-color: $color-green-2
        transform: rotateZ(45deg) translateX(-50%)

        &:before
          content: ''
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          background-color: rgba($color-black, 0.25)
          opacity: 0
          transition: opacity $layout-speed $layout-easing

          .active.change-on-active &
            opacity: 1


    &-icon
      height: $sidebar-icon-size
      position: relative
      z-index: 2

      &-arrow
        font-size: $font-arrow-down-size


    &-text
      position: relative
      z-index: 2
      font-size: $sidebar-item-size
      margin: 10px 0 12px


  &-sublist
    &-1
      +hidden-scroll(y)
      border-top: 1px solid $color-green-1

      .open-sub-1 &
        display: block


      &-loader
        @extend .dwa-flex-center

        color: $color-white
        height: 65px
        font-size: 11px


      &-item
        @extend .dwa-flex-column

        border-bottom: 1px solid rgba($color-black, 0.1)
        position: relative

        &:before
          content: ''
          position: absolute
          left: 0
          bottom: 0
          width: 100%
          height: 1px
          color: red

        &:last-child
          border: 0


      &-image
        min-width: $sidebar-kiosk-icon-width
        max-width: $sidebar-kiosk-icon-width
        margin-right: 10px


      &-link
        @extend .dwa-hover-opacity

        padding: 22px 15px 20px
        color: $color-green-1
        display: flex
        align-items: center

        &.active
          color: $color-white
          pointer-events: none


    &-2
      display: none

      .open-sub-2 &
        display: block


      &-item
        @extend .dwa-flex-column


      &-image
        min-width: $sidebar-kiosk-icon-width * .75
        max-width: $sidebar-kiosk-icon-width * .75
        margin-right: 10px


      &-link
        @extend .dwa-hover-opacity

        padding: 14px 20px 14px 20px
        display: flex
        align-items: center
        font-size: 0.92em

        &.active
          text-decoration: underline


  &-tree
    margin-left: 15px
    margin-bottom: 20px

    &-list
      margin: 0 5px 10px 20px
      font-size: 0.9em

    &-link
      @extend .dwa-hover-opacity

      font-style: italic
      position: relative
      display: block

      &:before
        content: "\2022"
        font-weight: bold
        display: inline-block
        position: absolute
        width: 5px
        left: -8px

      &.active
        text-decoration: underline


// Icons

.dashboard-icon
  +icon-image('chart-bar')

.kiosks-icon
  +icon-image('file-archive')

.preparekiosks-icon
  +icon-image('file-code')

.favorite-icon
  +icon-image('star')

.arrow-down-icon
  +icon-image('caret-down')
